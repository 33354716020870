import {VuexModule, Module, Mutation, Action} from "vuex-class-modules";
import store from '@/store';

@Module
export class UserModule extends VuexModule {

  username = '';
  userFormValid = false;

  @Mutation
  setUsername(userName: string) {
    this.username = userName;
  }

  @Mutation
  setUserForm(formValid: boolean) {
    this.userFormValid = formValid;
  }

  @Action
  actionSetUsername(userName: string) {
    this.setUsername(userName);
  }

  @Action
  actionSetUserForm(formValid: boolean) {
    this.setUserForm(formValid);
  }

  get getUsername() {
    return this.username;
  }

  get getUserForm() {
    return this.userFormValid;
  }
}

export const userModule = new UserModule({store, name: "user"});
