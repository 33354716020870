import store from '@/store/index';
import {Action, Module, Mutation, VuexModule} from 'vuex-class-modules';
import {Lei} from "@/store/model";

@Module
export class LeiStorageModule extends VuexModule {
  leis: Lei[] = [
    {
      legalEntity: {
        legalName: 'adesso SE',
        legalForm: 'Europäische Aktiengesellschaft SGST',
        jurisdiction: 'Germany (DE)',
        businessRegistryIdentifier: 'HRB 20663',
        status: 'ACTIVE'
      },
      legalAddress: {
        street: 'Adessoplatz 1',
        postalCode: '44269',
        city: 'Dortmund',
        region: 'DE-NW',
        country: 'Deutschland'
      },
      headquartersAddress: {
        street: 'Adessoplatz 1',
        postalCode: '44269',
        city: 'Dortmund',
        region: 'DE-NW',
        country: 'Deutschland'
      },
      registrationDetails: {
        lei: '529900KOICE97ZSA1O52',
        status: 'RETIRED',
        initialStatus: new Date('2013-11-22'),
        nextRenewal: new Date('2022-12-07'),
        lastUpdate: new Date('2021-10-12'),
        managing: '5299000J2N45DDNE4Y28',
        validationSources: 'FULLY_CORROBORATED'
      },
    },
    {
      legalEntity: {
        legalName: 'adesso orange AG',
        legalForm: 'Aktiengesellschaft 6QQB',
        jurisdiction: 'Germany (DE)',
        businessRegistryIdentifier: 'HRB 213652',
        status: 'ACTIVE'
      },
      legalAddress: {
        street: 'Robert-Hensling-Straße 11',
        postalCode: '31789',
        city: 'Hameln',
        region: '',
        country: 'Deutschland'
      },
      headquartersAddress: {
        street: 'Robert-Hensling-Straße 11',
        postalCode: '31789',
        city: 'Hameln',
        region: '',
        country: 'Deutschland'
      },
      registrationDetails: {
        lei: '894500URU7GM1TODCD79',
        status: 'LAPSED',
        initialStatus: new Date('2022-03-29'),
        nextRenewal: new Date('2023-03-29'),
        lastUpdate: new Date('2022-03-29'),
        managing: '529900F6BNUR3RJ2WH29',
        validationSources: 'FULLY_CORROBORATED'
      },
    },
    {
      legalEntity: {
        legalName: 'Adesso B.V.',
        legalForm: 'besloten vennootschap met beperkte aansprakelijkheid 54M6',
        jurisdiction: 'Netherlands (NL)',
        businessRegistryIdentifier: '58667598',
        status: 'ACTIVE',
      },
      legalAddress: {
        street: 'Zwolleweg 9',
        postalCode: '3771NR',
        city: 'Barneveld',
        region: '',
        country: 'Niederlande'
      },
      headquartersAddress: {
        street: 'Zwolleweg 9',
        postalCode: '3771NR',
        city: 'Barneveld',
        region: '',
        country: 'Niederlande'
      },
      registrationDetails: {
        lei: '724500Q922FCHHWN2546',
        status: 'ISSUED',
        initialStatus: new Date('2022-01-05'),
        nextRenewal: new Date('2023-01-05'),
        lastUpdate: new Date('2022-01-05'),
        managing: '724500A93Z8V1MJK5349',
        validationSources: 'FULLY_CORROBORATED'
      },
    }];

  @Mutation
  updateLei(lei: Lei) {
    const index = this.leis.map(oldLei => oldLei.registrationDetails.lei).indexOf(lei.registrationDetails.lei);
    this.leis.splice(index, 1, lei);
  }

  @Mutation
  addLei(lei: Lei) {
    this.leis.push(lei);
  }

  @Action
  actionUpdateLei(lei: Lei) {
    this.updateLei(lei);
  }

  @Action
  actionAddLei(lei: Lei) {
    this.addLei(lei)
  }

  get getLeis(): Lei[] {
    return this.leis;
  }

}

export const leiStorageModule = new LeiStorageModule({store, name: "lei-storage"});
