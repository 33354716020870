
import {Vue} from 'vue-class-component';
import {Prop} from 'vue-property-decorator';

export default class SelectorCountry extends Vue {

  placeholder = 'Bitte wählen';
  countries = [
    {value: 'Afghanistan', code: 'AFG'},
    {value: 'Ägypten', code: 'EGY'},
    {value: 'Åland', code: 'ALA'},
    {value: 'Albanien', code: 'ALB'},
    {value: 'Algerien', code: 'DZA'},
    {value: 'Amerikanisch-Samoa', code: 'ASM'},
    {value: 'Amerikanische Jungferninseln', code: 'VIR'},
    {value: 'Andorra', code: 'AND'},
    {value: 'Angola', code: 'AGO'},
    {value: 'Anguilla', code: 'AIA'},
    {value: 'Antarktis', code: 'ATA'},
    {value: 'Antigua und Barbuda', code: 'ATG'},
    {value: 'Äquatorialguinea', code: 'GNQ'},
    {value: 'Argentinien', code: 'ARG'},
    {value: 'Armenien', code: 'ARM'},
    {value: 'Aruba', code: 'ABW'},
    {value: 'Aserbaidschan', code: 'AZE'},
    {value: 'Äthiopien', code: 'ETH'},
    {value: 'Australien', code: 'AUS'},
    {value: 'Bahamas', code: 'BHS'},
    {value: 'Bahrain', code: 'BHR'},
    {value: 'Bangladesch', code: 'BGD'},
    {value: 'Barbados', code: 'BRB'},
    {value: 'Belarus', code: 'BLR'},
    {value: 'Belgien', code: 'BEL'},
    {value: 'Belize', code: 'BLZ'},
    {value: 'Benin', code: 'BEN'},
    {value: 'Bermuda', code: 'BMU'},
    {value: 'Bhutan', code: 'BTN'},
    {value: 'Bolivien', code: 'BOL'},
    {value: 'Bosnien und Herzegowina', code: 'BIH'},
    {value: 'Botswana', code: 'BWA'},
    {value: 'Bouvetinsel', code: 'BVT'},
    {value: 'Brasilien', code: 'BRA'},
    {value: 'Britische Jungferninseln', code: 'VGB'},
    {value: 'Britisches Territorium im Indischen Ozean', code: 'IOT'},
    {value: 'Brunei', code: 'BRN'},
    {value: 'Bulgarien', code: 'BGR'},
    {value: 'Burkina Faso', code: 'BFA'},
    {value: 'Burundi', code: 'BDI'},
    {value: 'Chile', code: 'CHL'},
    {value: 'China', code: 'CHN'},
    {value: 'Clipperton', code: 'CPT'},
    {value: 'Cookinseln', code: 'COK'},
    {value: 'Costa Rica', code: 'CRI'},
    {value: 'Curaçao', code: 'CUW'},
    {value: 'Dänemark', code: 'DNK'},
    {value: 'Deutschland', code: 'DEU'},
    {value: 'Dominica', code: 'DMA'},
    {value: 'Dominikanische Republik', code: 'DOM'},
    {value: 'Dschibuti', code: 'DJI'},
    {value: 'Ecuador', code: 'ECU'},
    {value: 'Elfenbeinküste', code: 'CIV'},
    {value: 'El Salvador', code: 'SLV'},
    {value: 'Eritrea', code: 'ERI'},
    {value: 'Estland', code: 'EST'},
    {value: 'Eswatini', code: 'SWZ'},
    {value: 'Falklandinseln', code: 'FLK'},
    {value: 'Färöer', code: 'FRO'},
    {value: 'Fidschi', code: 'FJI'},
    {value: 'Finnland', code: 'FIN'},
    {value: 'Frankreich', code: 'FRA'},
    {value: 'Frankreich (metropolitanes)', code: 'FXX'},
    {value: 'Französisch-Guayana', code: 'GUF'},
    {value: 'Französisch-Polynesien', code: 'PYF'},
    {value: 'Französische Süd- und Antarktisgebiete', code: 'ATF'},
    {value: 'Gabun', code: 'GAB'},
    {value: 'Gambia', code: 'GMB'},
    {value: 'Georgien', code: 'GEO'},
    {value: 'Ghana', code: 'GHA'},
    {value: 'Gibraltar', code: 'GIB'},
    {value: 'Grenada', code: 'GRD'},
    {value: 'Griechenland', code: 'GRC'},
    {value: 'Grönland', code: 'GRL'},
    {value: 'Guadeloupe', code: 'GLP'},
    {value: 'Guam', code: 'GUM'},
    {value: 'Guatemala', code: 'GTM'},
    {value: 'Guernsey', code: 'GGY'},
    {value: 'Guinea', code: 'GIN'},
    {value: 'Guinea-Bissau', code: 'GNB'},
    {value: 'Guyana', code: 'GUY'},
    {value: 'Haiti', code: 'HTI'},
    {value: 'Heard und McDonaldinseln', code: 'HMD'},
    {value: 'Honduras', code: 'HND'},
    {value: 'Hongkong', code: 'HKG'},
    {value: 'Indien', code: 'IND'},
    {value: 'Indonesien', code: 'IDN'},
    {value: 'Isle of Man', code: 'IMN'},
    {value: 'Irak', code: 'IRQ'},
    {value: 'Iran', code: 'IRN'},
    {value: 'Irland', code: 'IRL'},
    {value: 'Island', code: 'ISL'},
    {value: 'Israel', code: 'ISR'},
    {value: 'Italien', code: 'ITA'},
    {value: 'Jamaika', code: 'JAM'},
    {value: 'Japan', code: 'JPN'},
    {value: 'Jemen', code: 'YEM'},
    {value: 'Jersey', code: 'JEY'},
    {value: 'Jordanien', code: 'JOR'},
    {value: 'Cayman Islands', code: 'CYM'},
    {value: 'Kambodscha', code: 'KHM'},
    {value: 'Kamerun', code: 'CMR'},
    {value: 'Kanada', code: 'CAN'},
    {value: 'Kap Verde', code: 'CPV'},
    {value: 'Kasachstan', code: 'KAZ'},
    {value: 'Katar', code: 'QAT'},
    {value: 'Kenia', code: 'KEN'},
    {value: 'Kirgisistan', code: 'KGZ'},
    {value: 'Kiribati', code: 'KIR'},
    {value: 'Kokosinseln', code: 'CCK'},
    {value: 'Kolumbien', code: 'COL'},
    {value: 'Komoren', code: 'COM'},
    {value: 'Demokratische Republik Kongo', code: 'COD'},
    {value: 'Republik Kongo', code: 'COG'},
    {value: 'Nordkorea', code: 'PRK'},
    {value: 'Südkorea', code: 'KOR'},
    {value: 'Kosovo', code: 'XXK'},
    {value: 'Kroatien', code: 'HRV'},
    {value: 'Kuba', code: 'CUB'},
    {value: 'Kuwait', code: 'KWT'},
    {value: 'Laos', code: 'LAO'},
    {value: 'Lesotho', code: 'LSO'},
    {value: 'Lettland', code: 'LVA'},
    {value: 'Libanon', code: 'LBN'},
    {value: 'Liberia', code: 'LBR'},
    {value: 'Libyen', code: 'LBY'},
    {value: 'Liechtenstein', code: 'LIE'},
    {value: 'Litauen', code: 'LTU'},
    {value: 'Luxemburg', code: 'LUX'},
    {value: 'Macau', code: 'MAC'},
    {value: 'Madagaskar', code: 'MDG'},
    {value: 'Malawi', code: 'MWI'},
    {value: 'Malaysia', code: 'MYS'},
    {value: 'Malediven', code: 'MDV'},
    {value: 'Mali', code: 'MLI'},
    {value: 'Malta', code: 'MLT'},
    {value: 'Marokko', code: 'MAR'},
    {value: 'Marshallinseln', code: 'MHL'},
    {value: 'Martinique', code: 'MTQ'},
    {value: 'Mauretanien', code: 'MRT'},
    {value: 'Mauritius', code: 'MUS'},
    {value: 'Mayotte', code: 'MYT'},
    {value: 'Mexiko', code: 'MEX'},
    {value: 'Mikronesien', code: 'FSM'},
    {value: 'Moldau', code: 'MDA'},
    {value: 'Monaco', code: 'MCO'},
    {value: 'Mongolei', code: 'MNG'},
    {value: 'Montenegro', code: 'MNE'},
    {value: 'Montserrat', code: 'MSR'},
    {value: 'Mosambik', code: 'MOZ'},
    {value: 'Myanmar', code: 'MMR'},
    {value: 'Namibia', code: 'NAM'},
    {value: 'Nauru', code: 'NRU'},
    {value: 'Nepal', code: 'NPL'},
    {value: 'Neukaledonien', code: 'NCL'},
    {value: 'Neuseeland', code: 'NZL'},
    {value: 'Nicaragua', code: 'NIC'},
    {value: 'Niederlande', code: 'NLD'},
    {value: 'Niger', code: 'NER'},
    {value: 'Nigeria', code: 'NGA'},
    {value: 'Niue', code: 'NIU'},
    {value: 'Nördliche Marianen', code: 'MNP'},
    {value: 'Nordmazedonien', code: 'MKD'},
    {value: 'Norfolkinsel', code: 'NFK'},
    {value: 'Norwegen', code: 'NOR'},
    {value: 'Oman', code: 'OMN'},
    {value: 'Österreich', code: 'AUT'},
    {value: 'Osttimor', code: 'TLS'},
    {value: 'Pakistan', code: 'PAK'},
    {value: 'Palästina', code: 'PSE'},
    {value: 'Palau', code: 'PLW'},
    {value: 'Panama', code: 'PAN'},
    {value: 'Papua-Neuguinea', code: 'PNG'},
    {value: 'Paraguay', code: 'PRY'},
    {value: 'Peru', code: 'PER'},
    {value: 'Philippinen', code: 'PHL'},
    {value: 'Pitcairninseln', code: 'PCN'},
    {value: 'Polen', code: 'POL'},
    {value: 'Portugal', code: 'PRT'},
    {value: 'Puerto Rico', code: 'PRI'},
    {value: 'Réunion', code: 'REU'},
    {value: 'Ruanda', code: 'RWA'},
    {value: 'Rumänien', code: 'ROU'},
    {value: 'Russland', code: 'RUS'},
    {value: 'Salomonen', code: 'SLB'},
    {value: 'Saint-Barthélemy', code: 'BLM'},
    {value: 'Saint-Martin (französischer Teil)', code: 'MAF'},
    {value: 'Sambia', code: 'ZMB'},
    {value: 'Samoa', code: 'WSM'},
    {value: 'San Marino', code: 'SMR'},
    {value: 'São Tomé und Príncipe', code: 'STP'},
    {value: 'Saudi-Arabien', code: 'SAU'},
    {value: 'Schweden', code: 'SWE'},
    {value: 'Schweiz', code: 'CHE'},
    {value: 'Senegal', code: 'SEN'},
    {value: 'Serbien', code: 'SRB'},
    {value: 'Seychellen', code: 'SYC'},
    {value: 'Sierra Leone', code: 'SLE'},
    {value: 'Simbabwe', code: 'ZWE'},
    {value: 'Singapur', code: 'SGP'},
    {value: 'Sint Maarten', code: 'SXM'},
    {value: 'Slowakei', code: 'SVK'},
    {value: 'Slowenien', code: 'SVN'},
    {value: 'Somalia', code: 'SOM'},
    {value: 'Spanien', code: 'ESP'},
    {value: 'Sri Lanka', code: 'LKA'},
    {value: 'St. Helena', code: 'SHN'},
    {value: 'St. Kitts und Nevis', code: 'KNA'},
    {value: 'St. Lucia', code: 'LCA'},
    {value: 'Saint-Pierre und Miquelon', code: 'SPM'},
    {value: 'St. Vincent und die Grenadinen', code: 'VCT'},
    {value: 'Südafrika', code: 'ZAF'},
    {value: 'Sudan', code: 'SDN'},
    {value: 'Südgeorgien und die Südlichen Sandwichinseln', code: 'SGS'},
    {value: 'Südsudan', code: 'SSD'},
    {value: 'Suriname', code: 'SUR'},
    {value: 'Spitzbergen und Jan Mayen', code: 'SJM'},
    {value: 'Syrien', code: 'SYR'},
    {value: 'Tadschikistan', code: 'TJK'},
    {value: 'Taiwan', code: 'TWN'},
    {value: 'Tansania', code: 'TZA'},
    {value: 'Thailand', code: 'THA'},
    {value: 'Togo', code: 'TGO'},
    {value: 'Tokelau', code: 'TKL'},
    {value: 'Tonga', code: 'TON'},
    {value: 'Trinidad und Tobago', code: 'TTO'},
    {value: 'Tristan da Cunha', code: 'TAA'},
    {value: 'Tschad', code: 'TCD'},
    {value: 'Tschechien', code: 'CZE'},
    {value: 'Tunesien', code: 'TUN'},
    {value: 'Türkei', code: 'TUR'},
    {value: 'Turkmenistan', code: 'TKM'},
    {value: 'Turks- und Caicosinseln', code: 'TCA'},
    {value: 'Tuvalu', code: 'TUV'},
    {value: 'Uganda', code: 'UGA'},
    {value: 'Ukraine', code: 'UKR'},
    {value: 'Ungarn', code: 'HUN'},
    {value: 'United States Minor Outlying Islands', code: 'UMI'},
    {value: 'Uruguay', code: '	URY'},
    {value: 'Usbekistan', code: 'UZB'},
    {value: 'Vanuatu', code: 'VUT'},
    {value: 'Vatikanstadt', code: 'VAT'},
    {value: 'Venezuela', code: 'VEN'},
    {value: 'Vereinigte Arabische Emirate', code: 'ARE'},
    {value: 'Vereinigte Staaten', code: 'USA'},
    {value: 'Vereinigtes Königreich', code: 'GBR'},
    {value: 'Vietnam', code: 'VNM'},
    {value: 'Wallis und Futuna', code: 'WLF'},
    {value: 'Weihnachtsinsel', code: 'CXR'},
    {value: 'Westsahara', code: 'ESH'},
    {value: 'Zentralafrikanische Republik', code: 'CAF'},
    {value: 'Zypern', code: 'CYP'},
  ];

  @Prop() modelValue!: string
  @Prop() onBlur!: string
  @Prop() valid!: boolean

  onChange(event: string) {
    this.$emit("update:modelValue", event);
    let code = this.countries.find(x => x.value === event);
    this.$emit('update-model', code?.code);
  }
}
