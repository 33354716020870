import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-65fcbc97"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "main" }
const _hoisted_2 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_header = _resolveComponent("base-header")!
  const _component_cart_table = _resolveComponent("cart-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("header", null, [
      _createVNode(_component_base_header, {
        "title-left": "Produkte",
        "title-right": _ctx.itemsInCart(),
        "title-main": "Warenkorb"
      }, null, 8, ["title-right"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_cart_table)
    ])
  ]))
}