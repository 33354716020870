
import {Options, Vue} from "vue-class-component";
import UserCurrent from "@/components/user/user-current.vue";

@Options({
  components: {
    UserCurrent
  }
})
export default class LeiUser extends Vue {
}
