
import { Options, Vue } from "vue-class-component";
import { companyModule } from "@/store/register/company";
import BaseInput from "@/components/base/base-input.vue";
import BaseButton from "@/components/base/base-button.vue";
import BaseLabel from "@/components/base/base-label.vue";
import SelectorCountry from "@/components/base/selector/selector-country.vue";
import BaseAutocomplete from "@/components/base/base-autocomplete.vue";

@Options({
  components: {
    BaseAutocomplete,
    SelectorCountry,
    BaseInput,
    BaseButton,
    BaseLabel,
  },
  emits: ["change-route"],
})
export default class RegisterStepperCompany extends Vue {
  company = { value: "", isValid: true };
  co = { value: "", isValid: true };
  land = { value: "", isValid: true };
  plz = { value: "", isValid: true };
  city = { value: "f", isValid: true };
  street = { value: "", isValid: true };
  nr = { value: "", isValid: true };
  code = "countryCode:DEU";
  formIsValid = true;

  // eslint-disable-next-line
  autocompleteData: any[] = [];
  // eslint-disable-next-line
  autocompleteCompanyData: any[] = [];
  // eslint-disable-next-line
  autocompletePlzData: any[] = [];
  // eslint-disable-next-line
  autocompleteCityData: any[] = [];
  // eslint-disable-next-line
  autocompleteStreetData: any[] = [];
  // eslint-disable-next-line
  autocompleteNrData: any[] = [];
  apiKey = "cn9bewzqlYNTFyQhy7Hky3O64MzA5xinLuACfHzccEU";

  updateModel(action: string, item: string) {
    let result = [];
    if (action === "plz") {
      result = this.autocompleteData.filter(
        (x: { postalCode: string }) => x.postalCode === item
      );
      if (result[0].city !== undefined) {
        this.city.value = result[0].city;
        this.validateCity();
      }
    }
    if (action === "city") {
      result = this.autocompleteData.filter(
        (x: { city: string }) => x.city === item
      );
      this.plz.value = result[0].postalCode;
      this.validatePlz();
    }
  }

  updateCode(code: string) {
    this.code = "countryCode:" + code;
  }

  backToUser() {
    this.$emit("change-route", "user");
  }

  validateCompany() {
    this.company.isValid = this.company.value.trim() !== "";
  }

  validateCo() {
    this.co.isValid = this.co.value.trim() !== "";
  }

  validateLand() {
    this.land.isValid = this.land.value.trim() !== "";
  }

  validatePlz() {
    this.plz.isValid = this.plz.value.trim() !== "";
  }

  validateCity() {
    this.city.isValid = this.city.value.trim() !== "";
  }

  validateStreet() {
    this.street.isValid = this.street.value.trim() !== "";
  }

  validateNr() {
    this.nr.isValid = this.nr.value.trim() !== "";
  }

  submitCompany() {
    this.validateCompany();
    this.validateCo();
    this.validateLand();
    this.validatePlz();
    this.validateCity();
    this.validateStreet();
    this.validateNr();

    if (
      this.company.isValid &&
      this.co.isValid &&
      this.land.isValid &&
      this.plz.isValid &&
      this.city.isValid &&
      this.street.isValid &&
      this.nr.isValid
    ) {
      const param = [];
      param.push(this.land.value);
      param.push(this.plz.value);
      param.push(this.city.value);
      param.push(this.street.value);
      param.push(this.nr.value);

      this.axios
        .get("https://geocode.search.hereapi.com/v1/autocomplete", {
          params: {
            q: param.toString(),
            apiKey: this.apiKey,
          },
        })
        .then((response) => {
          if (response.data.items.length > 0) {
            const result = response.data.items[0].title.split(" ");
            this.formIsValid = result.length > 4;
            if (this.formIsValid) {
              companyModule.actionSetCompany(this.company.value);
              companyModule.actionSetCo(this.co.value);
              companyModule.actionSetLand(this.land.value);
              companyModule.actionSetPlz(this.plz.value);
              companyModule.actionSetCity(this.city.value);
              companyModule.actionSetStreet(this.street.value);
              companyModule.actionSetNr(this.nr.value);
              companyModule.actionSetCompanyForm(this.formIsValid);
              this.$emit("change-route", "contact");
            }
          } else {
            this.formIsValid = false;
          }
        });
    }
  }

  autocompleteCompany() {
    this.autocompleteData = [];
    this.autocompleteCompanyData = [];

    if (this.company.isValid && this.company.value.length > 2) {
      this.axios
        .get(`http://localhost:8080/api/${this.company.value}`, {
          params: {
            count: 5,
          },
        })
        .then((response) => {
          this.autocompleteData = [];
          this.autocompleteCompanyData = [];
          for (let i = 0; i < response.data.results.length; i++) {
            this.autocompleteCompanyData.push(
              response.data.results[i].data.NAME[0]
            );
            this.autocompleteData.push(response.data.results[i]);
          }
        });
    }
  }

  autocompleteCity() {
    this.autocompleteData = [];
    this.autocompleteCityData = [];
    let param = "";
    if (this.land.value === "") {
      this.land.value = "Deutschland";
      this.validateLand();
    }
    if (this.city.value !== "" && this.city.value.length > 3) {
      param = this.city.value;
    }
    if (param.length > 0) {
      this.axios
        .get("https://geocode.search.hereapi.com/v1/autocomplete", {
          params: {
            q: param,
            in: this.code,
            types: "city",
            lang: "DEU",
            apiKey: this.apiKey,
          },
        })
        .then((response) => {
          for (let i = 0; i < response.data.items.length; i++) {
            this.autocompleteCityData.push(
              response.data.items[i]?.address.city
            );
            this.autocompleteData.push(response.data.items[i].address);
            if (
              this.plz.value === "" ||
              (response.data.items?.length === 1 && this.plz.value.length < 5)
            ) {
              this.plz.value = response.data.items[i]?.address.postalCode;
              this.validatePlz();
            }
          }
        });
    }
  }

  autocompletePlz() {
    this.autocompleteData = [];
    this.autocompletePlzData = [];
    let param = this.plz.value;
    if (this.land.value === "") {
      this.land.value = "Deutschland";
      this.validateLand();
    }

    if (param !== "" && param.length > 2 && param.length < 11) {
      //https://www.google.com/search?q=zip+code+length+international
      this.axios
        .get("https://geocode.search.hereapi.com/v1/autocomplete", {
          params: {
            q: param,
            in: this.code,
            types: "postalCode",
            lang: "DEU",
            apiKey: this.apiKey,
          },
        })
        .then((response) => {
          for (let i = 0; i < response.data.items.length; i++) {
            this.autocompletePlzData.push(
              response.data.items[i]?.address.postalCode
            );
            this.autocompleteData.push(response.data.items[i].address);
            if (this.city.value === "" || response.data.items?.length === 1) {
              if (response.data.items[i]?.address.city !== undefined) {
                this.city.value = response.data.items[i]?.address.city;
                this.validateCity();
              }
            }
          }
        });
    }
  }

  autocompleteStreet() {
    this.autocompleteStreetData = [];
    let param = [];
    if (this.land.value === "") {
      this.land.value = "Deutschland";
    }
    if (this.street.value !== "" && this.street.value.length > 3) {
      param.push(this.street.value);
    }
    if (param.length > 0) {
      if (this.plz.value !== "" && this.plz.value.length > 3) {
        param.unshift(this.plz.value);
      }
      if (this.city.value !== "" && this.city.value.length > 3) {
        param.unshift(this.city.value);
      }
      this.axios
        .get("https://geocode.search.hereapi.com/v1/autocomplete", {
          params: {
            q: param.toString(),
            in: this.code,
            lang: "DEU",
            apiKey: this.apiKey,
          },
        })
        .then((response) => {
          for (let i = 0; i < response.data.items.length; i++) {
            if (
              !this.autocompleteStreetData.includes(
                response.data.items[i]?.address.street
              )
            ) {
              this.autocompleteStreetData.push(
                response.data.items[i]?.address.street
              );
            }
          }
        });
    }
  }

  autocompleteNr() {
    this.autocompleteNrData = [];
    let param = [];
    if (this.land.value === "") {
      this.land.value = "Deutschland";
    }
    if (this.plz.value !== "" && this.plz.value.length > 3) {
      param.push(this.plz.value);
    }
    if (this.city.value !== "" && this.city.value.length > 3) {
      param.push(this.city.value);
    }
    if (this.street.value !== "" && this.street.value.length > 3) {
      param.push(" " + this.street.value);
    }
    if (this.nr.value !== "") {
      param.push("" + this.nr.value);
    }
    if (param.length > 0) {
      this.axios
        .get("https://geocode.search.hereapi.com/v1/autocomplete", {
          params: {
            q: param.toString(),
            in: this.code,
            lang: "DEU",
            apiKey: this.apiKey,
          },
        })
        .then((response) => {
          for (let i = 0; i < response.data.items.length; i++) {
            this.autocompleteStreetData.push(
              response.data.items[i]?.address.street
            );
          }
        });
    }
  }

  mounted() {
    this.company.value = companyModule.getCompany;
    this.co.value = companyModule.getCo;
    this.land.value = companyModule.getLand;
    this.plz.value = companyModule.getPlz;
    this.city.value = companyModule.getCity;
    this.street.value = companyModule.getStreet;
    this.nr.value = companyModule.getNr;
  }
}
