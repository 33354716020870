
import {Options, Vue} from "vue-class-component";
import DashboardTable from "@/components/dashboard/dashboard-table.vue";
import SubscriptionSelection from "@/components/dashboard/subscription-selection.vue";
import BaseHeader from "@/components/base/base-header.vue";

@Options({
  components: {
    BaseHeader,
    DashboardTable,
    SubscriptionSelection,
  }
})
export default class LeiDashboard extends Vue {

}
