
import {Options, Vue} from "vue-class-component";
import LeiDetails from "@/components/base/lei-details.vue";
import {Lei} from "@/store/model";
import {leiStorageModule} from "@/store/lei-storage";

@Options({
  components: {
    LeiDetails
  }
})
export default class LeiCartDetails extends Vue {
  lei!: Lei;

  created(){
    this.lei = leiStorageModule.getLeis.find(item => item.registrationDetails.lei === this.$route.params.id.toString()) as Lei;
  }
}
