import store from '@/store';
import {Action, Module, Mutation, VuexModule} from 'vuex-class-modules';
import {Lei} from "@/store/model";

@Module
export class CartModule extends VuexModule {
  types: string[] = [];
  cartItems: Lei[] = [];

  @Mutation
  addType(type: string){
    this.types.push(type);
  }

  @Mutation
  addCartItem(lei: Lei) {
    this.cartItems.push(lei);
    console.log(this.cartItems.length);
  }

  @Action
  actionAddType(type: string){
    this.addType(type);
  }

  @Action
  actionAddCartItem(lei: Lei) {
    this.addCartItem(lei);
  }

  @Mutation
  deleteCart(){
    this.cartItems.splice(0, this.cartItems.length);
    this.types.splice(0, this.types.length);
  }

  @Action
  actionDeleteCart() {
    this.deleteCart();
  }

  @Mutation
  deleteCartItem(id: string) {
    this.cartItems.splice(this.cartItems.map(item => item.registrationDetails.lei).indexOf(id),1);
    this.types.splice(this.cartItems.map(item => item.registrationDetails.lei).indexOf(id),1);
  }

  @Action
  actionDeleteCartItem(id: string) {
    this.deleteCartItem(id);
  }

  get getCartItems(): Lei[] {
    console.log(this.cartItems.length);
    return this.cartItems;
  }

  get getCartAmount() {
    return this.cartItems.length;
  }

  get getTypes() {
    return this.types;
  }
}

export const cartModule = new CartModule({store, name: "cart"});
