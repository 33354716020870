
import {Options, Vue} from "vue-class-component";
import {Prop} from 'vue-property-decorator';

@Options({
  emits: ['click']
})
export default class BaseToggle extends Vue {

  @Prop() value!: boolean
  @Prop() reverse!: boolean
  @Prop() checkedText!: string
  @Prop() uncheckedText!: string
  @Prop() width!: string
  @Prop() height!: string
  @Prop() checkedBg!: string
  @Prop() uncheckedBg!: string
  @Prop() checkedDotColor!: string
  @Prop() uncheckedDotColor!: string
  @Prop() checkedColor!: string
  @Prop() uncheckedColor!: string
  @Prop() fontSize!: string
  @Prop() fontWeight!: string

  toggleButton() {
    this.$emit('click', this.value)
  }

  get bgStyle() {
    return {
      width: +this.width + 'px',
      height: +this.height + 'px',
      background: this.value ? this.checkedBg : this.uncheckedBg,
    };
  }

  get dotStyle() {
    return {
      background: this.value ? this.checkedDotColor : this.uncheckedDotColor,
      width: +this.height - 8 + 'px',
      height: +this.height - 8 + 'px',
      'margin-left': this.value ? (+this.width - (+this.height - 3) + 'px') : '5px',
    };
  }

  get textStyle() {
    return {
      'font-weight': this.fontWeight,
      'font-size': `${+this.fontSize}px`,
      color: this.value ? this.checkedColor : this.uncheckedColor,
      right: this.value ? `${+this.height - 3}px` : 'auto',
      left: this.value ? 'auto' : `${+this.height - 3}px`,
    };
  }
}
