import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2eb2cffa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "pagination" }
const _hoisted_2 = { class: "pagination-item" }
const _hoisted_3 = ["disabled"]
const _hoisted_4 = { class: "pagination-item" }
const _hoisted_5 = ["disabled"]
const _hoisted_6 = ["onClick", "disabled"]
const _hoisted_7 = { class: "pagination-item" }
const _hoisted_8 = ["disabled"]
const _hoisted_9 = { class: "pagination-item" }
const _hoisted_10 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    _createElementVNode("li", _hoisted_2, [
      _createElementVNode("button", {
        type: "button",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClickFirstPage && _ctx.onClickFirstPage(...args))),
        disabled: _ctx.isInFirstPage
      }, " Erste ", 8, _hoisted_3)
    ]),
    _createElementVNode("li", _hoisted_4, [
      _createElementVNode("button", {
        type: "button",
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onClickPreviousPage && _ctx.onClickPreviousPage(...args))),
        disabled: _ctx.isInFirstPage
      }, " Vorherige ", 8, _hoisted_5)
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pages, (page) => {
      return (_openBlock(), _createElementBlock("li", {
        key: page.name,
        class: "pagination-item"
      }, [
        _createElementVNode("button", {
          type: "button",
          onClick: ($event: any) => (_ctx.onClickPage(page.name)),
          disabled: page.isDisabled,
          class: _normalizeClass({ active: _ctx.isPageActive(page.name) })
        }, _toDisplayString(page.name), 11, _hoisted_6)
      ]))
    }), 128)),
    _createElementVNode("li", _hoisted_7, [
      _createElementVNode("button", {
        type: "button",
        onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onClickNextPage && _ctx.onClickNextPage(...args))),
        disabled: _ctx.isInLastPage
      }, " Nächste ", 8, _hoisted_8)
    ]),
    _createElementVNode("li", _hoisted_9, [
      _createElementVNode("button", {
        type: "button",
        onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onClickLastPage && _ctx.onClickLastPage(...args))),
        disabled: _ctx.isInLastPage
      }, " Letzte ", 8, _hoisted_10)
    ])
  ]))
}