
import {Options, Vue} from "vue-class-component";
import RequestOutlineHeader from '@/components/request/outline/request-outline-header.vue';
import RequestStepper from '@/components/request/request-stepper.vue';
import BaseFooter from "@/components/base/base-footer.vue";

@Options({
  components: {
    RequestOutlineHeader,
    RequestStepper,
    BaseFooter
  }
})
export default class LeiRequest extends Vue {

  heading = "Unternehmen";

  changeHeading(step: string) {
    switch (step) {
      case "company":
        this.heading = "Unternehmen";
        break;
      case "address":
        this.heading = "Adresse";
        break;
      case "parent":
        this.heading = "Muttergesellschaft";
        break;
      case "result":
        this.heading = "Abschluss";
        break;
      default:
        this.heading = "";
    }
  }

}
