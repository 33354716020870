import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fe55c3ca"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "label" }
const _hoisted_2 = { class: "auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_label = _resolveComponent("base-label")!
  const _component_base_autocomplete = _resolveComponent("base-autocomplete")!
  const _component_base_input = _resolveComponent("base-input")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_base_label, { title: 'Unternehmen*' }),
      _createVNode(_component_base_label, {
        margin: '290px',
        title: 'Registernummer*'
      })
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_base_autocomplete, {
        items: _ctx.autocompleteCompanyData,
        modelValue: _ctx.company.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.company.value) = $event)),
        valid: _ctx.company.isValid,
        onBlur: _ctx.validateCompany,
        onKeyUp: _ctx.autocompleteCompany,
        auto: 'company',
        dropdown: true,
        onUpdateModel: _ctx.updateModel,
        width: '300px'
      }, null, 8, ["items", "modelValue", "valid", "onBlur", "onKeyUp", "onUpdateModel"]),
      _createVNode(_component_base_input, {
        modelValue: _ctx.regId.value,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.regId.value) = $event)),
        valid: _ctx.regId.isValid,
        width: '300px',
        onBlur: _ctx.validateRegId
      }, null, 8, ["modelValue", "valid", "onBlur"])
    ])
  ], 64))
}