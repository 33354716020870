import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-216ffc55"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  value: "",
  disabled: "",
  selected: ""
}
const _hoisted_2 = ["value", "selected"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("select", {
    onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onChange($event.target.value))),
    class: _normalizeClass({invalid: !_ctx.valid}),
    onBlur: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onBlur && _ctx.onBlur(...args)))
  }, [
    _createElementVNode("option", _hoisted_1, _toDisplayString(_ctx.placeholder), 1),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.salutations, (salutation) => {
      return (_openBlock(), _createElementBlock("option", {
        key: salutation,
        value: salutation,
        selected: salutation===_ctx.modelValue
      }, _toDisplayString(salutation), 9, _hoisted_2))
    }), 128))
  ], 34))
}