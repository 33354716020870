import {VuexModule, Module, Mutation, Action} from "vuex-class-modules";
import store from '@/store';


@Module
export class ResultModule extends VuexModule {

  password = '';
  passwordConfirm = '';
  captchaValid = false;
  resultFormValid = false;

  @Mutation
  setPassword(password: string) {
    this.password = password;
  }

  @Mutation
  setPasswordConfirm(passwordConfirm: string) {
    this.passwordConfirm = passwordConfirm;
  }

  @Mutation
  setCaptcha(valid: boolean) {
    this.captchaValid = valid;
  }

  @Mutation
  setResultForm(valid: boolean) {
    this.resultFormValid = valid;
  }

  @Action
  actionSetPassword(password: string) {
    this.setPassword(password)
  }

  @Action
  actionSetPasswordConfirm(passwordConfirm: string) {
    this.setPasswordConfirm(passwordConfirm)
  }

  @Action
  actionSetCaptcha(valid: boolean) {
    this.setCaptcha(valid)
  }

  @Action
  actionSetResultForm(valid: boolean) {
    this.setResultForm(valid)
  }


  get getPassword() {
    return this.password;
  }

  get getPasswordConfirm() {
    return this.passwordConfirm;
  }

  get getCaptcha() {
    return this.captchaValid;
  }

  get getResultForm() {
    return this.resultFormValid;
  }

}

export const resultModule = new ResultModule({store, name: "result"});
