import {createRouter, createWebHistory} from 'vue-router'
import LeiRegister from '@/views/lei-register.vue'
import LeiSearch from '@/views/lei-search.vue';
import LeiSearchDetails from '@/views/lei-search-details.vue';
import LeiUser from '@/views/lei-user.vue';
import LeiDashboard from '@/views/lei-dashboard.vue';
import LeiCart from "@/views/lei-cart.vue";
import LeiDashboardDetails from "@/views/lei-dashboard-details.vue";
import LeiRequest from '@/views/lei-request.vue';
import LeiCartDetails from '@/views/lei-cart-details.vue';
import LeiEmployee from "@/views/lei-employee.vue";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {path: '/', redirect: '/register'},
    {path: '/register', component: LeiRegister},
    {path: '/request', component: LeiRequest},
    {path: '/user/:id', component: LeiUser, props: true},
    {path: '/search', component: LeiSearch},
    {path: '/search/:id', component: LeiSearchDetails, props: true},
    {path: '/dashboard', component: LeiDashboard},
    {path: '/dashboard/:id', component: LeiDashboardDetails, props: true},
    {path: '/cart', component: LeiCart},
    {path: '/cart/:id', component: LeiCartDetails, props: true},
    {path: '/employee', component: LeiEmployee, props: true},
    {path: '/:notFound(.*)', redirect: '/register'},
  ]
})

export default router
