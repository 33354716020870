import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5e67e20b"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "form"
}
const _hoisted_2 = {
  key: 2,
  class: "form"
}
const _hoisted_3 = {
  key: 3,
  class: "form"
}
const _hoisted_4 = {
  key: 4,
  class: "form"
}
const _hoisted_5 = {
  key: 5,
  class: "form"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_register_stepper_header = _resolveComponent("register-stepper-header")!
  const _component_register_stepper_user = _resolveComponent("register-stepper-user")!
  const _component_register_stepper_company = _resolveComponent("register-stepper-company")!
  const _component_register_stepper_contact = _resolveComponent("register-stepper-contact")!
  const _component_register_stepper_result = _resolveComponent("register-stepper-result")!
  const _component_register_stepper_success = _resolveComponent("register-stepper-success")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.currentRoute !== 'success')
      ? (_openBlock(), _createBlock(_component_register_stepper_header, {
          key: 0,
          onChangeRoute: _ctx.setRoute,
          currentRoute: _ctx.currentRoute
        }, null, 8, ["onChangeRoute", "currentRoute"]))
      : _createCommentVNode("", true),
    (_ctx.currentRoute === 'user')
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_register_stepper_user, { onChangeRoute: _ctx.setRoute }, null, 8, ["onChangeRoute"])
        ]))
      : (_ctx.currentRoute === 'company')
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_register_stepper_company, { onChangeRoute: _ctx.setRoute }, null, 8, ["onChangeRoute"])
          ]))
        : (_ctx.currentRoute === 'contact')
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_register_stepper_contact, { onChangeRoute: _ctx.setRoute }, null, 8, ["onChangeRoute"])
            ]))
          : (_ctx.currentRoute === 'result')
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(_component_register_stepper_result, { onChangeRoute: _ctx.setRoute }, null, 8, ["onChangeRoute"])
              ]))
            : (_ctx.currentRoute === 'success')
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createVNode(_component_register_stepper_success, { onChangeRoute: _ctx.setRoute }, null, 8, ["onChangeRoute"])
                ]))
              : _createCommentVNode("", true)
  ], 64))
}