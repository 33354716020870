
import { Options, Vue } from "vue-class-component";

import router from "@/router";
import stringUtil from "@/util/stringUtil";
import BaseButton from "@/components/base/base-button.vue";
import { Lei } from "@/store/model";
import { Prop } from "vue-property-decorator";

@Options({
  components: {
    BaseButton,
  },
})
export default class LeiDetails extends Vue {
  StringUtil = stringUtil;

  @Prop() lei!: Lei;
  @Prop() backUri!: string;

  back() {
    router.push({ path: `/${this.backUri}/` });
  }
}
