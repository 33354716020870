
import {Vue} from "vue-class-component";
import {Prop} from 'vue-property-decorator';

export default class BasePagination extends Vue {

  @Prop() maxVisibleButtons = 3
  @Prop() totalPages!: number
  @Prop() perPage!: number
  @Prop() currentPage!: number

  get startPage() {
    if (this.currentPage === 1) {
      return 1;
    }
    if (this.currentPage === this.totalPages) {
      return this.totalPages - this.maxVisibleButtons + 1;
    }
    return this.currentPage - 1;
  }

  get endPage() {
    return Math.min(this.startPage + this.maxVisibleButtons - 1, this.totalPages);
  }

  get pages() {
    const range = [];
    for (let i = this.startPage; i <= this.endPage; i++) {
      range.push({name: i, isDisabled: i === this.currentPage});
    }
    return range;
  }

  get isInFirstPage() {
    return this.currentPage === 1;
  }

  get isInLastPage() {
    return this.currentPage === this.totalPages;
  }

  onClickFirstPage() {
    this.$emit('pagechanged', 1);
  }

  onClickPreviousPage() {
    this.$emit('pagechanged', this.currentPage - 1);
  }

  onClickPage(page: number) {
    this.$emit('pagechanged', page);
  }

  onClickNextPage() {
    this.$emit('pagechanged', this.currentPage + 1);
  }

  onClickLastPage() {
    this.$emit('pagechanged', this.totalPages);
  }

  isPageActive(page: number) {
    return this.currentPage === page;
  }
}
