import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-74b2bb44"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "form-control" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "label" }
const _hoisted_4 = { class: "input" }
const _hoisted_5 = { class: "buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_request_base_company = _resolveComponent("request-base-company")!
  const _component_base_label = _resolveComponent("base-label")!
  const _component_base_input = _resolveComponent("base-input")!
  const _component_base_button = _resolveComponent("base-button")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (!_ctx.formValid || !_ctx.taxId.isValid)
        ? (_openBlock(), _createElementBlock("p", _hoisted_2, " Unternehmensdaten überprüfen"))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_request_base_company, {
      type: 'stepper-company',
      onInterface: _ctx.getCompanyInterface,
      onValidForm: _ctx.validCompany
    }, null, 8, ["onInterface", "onValidForm"]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_base_label, { title: 'Umsatzsteuer-Identifikationsnummer*' })
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_base_input, {
        modelValue: _ctx.taxId.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.taxId.value) = $event)),
        valid: _ctx.taxId.isValid,
        width: '300px',
        onBlur: _ctx.validateTaxId
      }, null, 8, ["modelValue", "valid", "onBlur"])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_base_button, {
        disabledButton: true,
        title: 'Zurück'
      }),
      _createVNode(_component_base_button, {
        title: 'Weiter',
        onClick: _ctx.submitCompany
      }, null, 8, ["onClick"])
    ])
  ], 64))
}