
import {Vue} from "vue-class-component";
import {Prop} from "vue-property-decorator";

export default class BaseHeader extends Vue {

  @Prop() titleLeft!: string
  @Prop() titleRight!: string
  @Prop() titleMain!: string
}
