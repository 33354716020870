
import {Vue} from 'vue-class-component';
import {Prop} from 'vue-property-decorator';

export default class BaseAutocomplete extends Vue {

  isOpen = false;

  @Prop() items!: string[]
  @Prop() modelValue!: string
  @Prop() valid!: boolean
  @Prop() onBlur!: string
  @Prop() onKeyUp!: string
  @Prop() dropdown!: boolean
  @Prop() auto!: string
  @Prop() width!: string
  @Prop() height = '53px'
  @Prop() maxLength!: string// = 'infinity'

  updateModel(item: string) {
    this.$emit("update:modelValue", item);
    this.$emit('update-model', this.auto, item)
    this.isOpen = false;
  }

  updateInput(event: string) {
    this.$emit("update:modelValue", event);
    if (this.items.length > 0) {
      this.isOpen = true;
    }
  }

  handleClickOutside(event: Event) {
    if (!this.$el.contains(event.target)) {
      this.isOpen = false;
    }
  }

  mounted() {
    document.addEventListener('focusin', this.handleClickOutside);
  }

  unmounted() {
    document.removeEventListener( 'focusin', this.handleClickOutside);
  }
}
