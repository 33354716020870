
import {Options, Vue} from "vue-class-component";
import RequestBaseCompany from '@/components/request/base/request-base-company.vue';
import BaseLabel from '@/components/base/base-label.vue';
import BaseInput from '@/components/base/base-input.vue';
import BaseButton from '@/components/base/base-button.vue';
import {requestCompanyModule} from '@/store/request/request-company';
import {requestResultModule} from '@/store/request/request-result';


@Options({
  components: {
    RequestBaseCompany,
    BaseLabel,
    BaseInput,
    BaseButton,
  },
  emits: ['change-route'],
})
export default class RequestStepperCompany extends Vue {

  company!: string;
  regId!: string;
  taxId = {value: '', isValid: true};
  formValid = true;

  // eslint-disable-next-line
  getCompanyInterface(companyInterface: any) {
    this.$options.companyInterface = companyInterface;
  }

  validCompany(valid: boolean, company: string, regId: string) {
    this.formValid = valid;
    this.company = company;
    this.regId = regId;
  }

  validateTaxId() {
    this.taxId.isValid = this.taxId.value.trim() !== '' && /DE[0-9]{9}/.test(this.taxId.value);
  }

  submitCompany() {
    this.$options.companyInterface.isFormValid();
    this.validateTaxId();
    if (this.formValid && this.taxId.isValid) {
      requestCompanyModule.actionSetCompany(this.company);
      requestCompanyModule.actionSetRegId(this.regId);
      requestCompanyModule.actionSetTaxId(this.taxId.value);
      requestCompanyModule.actionSetCompanyForm(true);
      requestResultModule.setCompany(this.company)
      this.$emit('change-route', 'address');
    }
  }

  mounted() {
    this.taxId.value = requestCompanyModule.getTaxId;
  }
}
