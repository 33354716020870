import {VuexModule, Module, Mutation, Action} from "vuex-class-modules";
import store from '@/store';

@Module
export class AddressModule extends VuexModule {

  value = true;
  plz = '';
  city = '';
  street = '';
  nr = '';
  juPlz = '';
  juCity = '';
  juStreet = '';
  juNr = '';
  addressFormValid = false;

  @Mutation
  reset() {
    this.value = true;
    this.plz = '';
    this.city = '';
    this.street = '';
    this.nr = '';
    this.juPlz = '';
    this.juCity = '';
    this.juStreet = '';
    this.juNr = '';
    this.addressFormValid = false;
  }

  @Mutation
  setValue(value: boolean) {
    this.value = value;
  }

  @Mutation
  setPlz(plz: string) {
    this.plz = plz;
  }

  @Mutation
  setCity(city: string) {
    this.city = city;
  }

  @Mutation
  setStreet(street: string) {
    this.street = street;
  }

  @Mutation
  setNr(nr: string) {
    this.nr = nr;
  }

  @Mutation
  setJuPlz(plz: string) {
    this.juPlz = plz;
  }

  @Mutation
  setJuCity(city: string) {
    this.juCity = city;
  }

  @Mutation
  setJuStreet(street: string) {
    this.juStreet = street;
  }

  @Mutation
  setJuNr(nr: string) {
    this.juNr = nr;
  }

  @Mutation
  setAddressForm(valid: boolean) {
    this.addressFormValid = valid;
  }

  @Action
  actionReset() {
    this.reset();
  }

  @Action
  actionSetValue(value: boolean) {
    this.setValue(value);
  }

  @Action
  actionSetPlz(plz: string) {
    this.setPlz(plz);
  }

  @Action
  actionSetCity(city: string) {
    this.setCity(city);
  }

  @Action
  actionSetStreet(street: string) {
    this.setStreet(street);
  }

  @Action
  actionSetNr(nr: string) {
    this.setNr(nr);
  }

  @Action
  actionSetJuPlz(plz: string) {
    this.setJuPlz(plz);
  }

  @Action
  actionSetJuCity(city: string) {
    this.setJuCity(city);
  }

  @Action
  actionSetJuStreet(street: string) {
    this.setJuStreet(street);
  }

  @Action
  actionSetJuNr(nr: string) {
    this.setJuNr(nr);
  }

  @Action
  actionSetAddressForm(valid: boolean) {
    this.setAddressForm(valid);
  }

  get getValue() {
    return this.value;
  }

  get getPlz() {
    return this.plz;
  }

  get getCity() {
    return this.city;
  }

  get getStreet() {
    return this.street;
  }

  get getNr() {
    return this.nr;
  }

  get getJuPlz() {
    return this.juPlz;
  }

  get getJuCity() {
    return this.juCity;
  }

  get getJuStreet() {
    return this.juStreet;
  }

  get getJuNr() {
    return this.juNr;
  }

  get getAddressForm() {
    return this.addressFormValid;
  }

}

export const addressModule = new AddressModule({store, name: "address"});
