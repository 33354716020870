
import { Vue } from "vue-class-component";
import { cartModule } from "@/store/cart/cart";
import { userStorageModule } from "@/store/user-storage";
import { computed } from "@vue/reactivity";
import router from "@/router";

export default class BaseNavmenu extends Vue {
  items = cartModule.getCartItems;

  accountText = computed(() => {
    return userStorageModule.getUsers.length - 1 < 4
      ? "Konto erstellen"
      : "Konto anzeigen";
  });

  accountPath = computed(() => {
    return userStorageModule.getUsers.length - 1 < 4
      ? "/register"
      : `/user/${userStorageModule.getUsers.length - 1}`;
  });

  accountClass = computed(() => {
    return router.currentRoute.value.path === "/register" ||
      router.currentRoute.value.path.startsWith("/user")
      ? "active"
      : undefined;
  });
}
