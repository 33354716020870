import {VuexModule, Module, Mutation, Action} from "vuex-class-modules";
import store from '@/store';

@Module
export class CompanyModule extends VuexModule {

  company = '';
  co = '';
  land = '';
  plz = '';
  city = '';
  street = '';
  nr = '';
  companyFormValid = false;

  @Mutation
  setCompany(company: string) {
    this.company = company;
  }

  @Mutation
  setCo(co: string) {
    this.co = co;
  }

  @Mutation
  setLand(land: string) {
    this.land = land;
  }

  @Mutation
  setPlz(plz: string) {
    this.plz = plz;
  }

  @Mutation
  setCity(city: string) {
    this.city = city;
  }

  @Mutation
  setStreet(street: string) {
    this.street = street;
  }

  @Mutation
  setNr(nr: string) {
    this.nr = nr;
  }

  @Mutation
  setCompanyForm(valid: boolean) {
    this.companyFormValid = valid;
  }

  @Action
  actionSetCompany(company: string) {
    this.setCompany(company);
  }


  @Action
  actionSetCo(co: string) {
    this.setCo(co);
  }

  @Action
  actionSetLand(land: string) {
    this.setLand(land);
  }

  @Action
  actionSetPlz(plz: string) {
    this.setPlz(plz);
  }

  @Action
  actionSetCity(city: string) {
    this.setCity(city);
  }

  @Action
  actionSetStreet(street: string) {
    this.setStreet(street);
  }

  @Action
  actionSetNr(nr: string) {
    this.setNr(nr);
  }

  @Action
  actionSetCompanyForm(valid: boolean) {
    this.setCompanyForm(valid);
  }

  get getCompany() {
    return this.company;
  }

  get getCo() {
    return this.co;
  }

  get getLand() {
    return this.land;
  }

  get getPlz() {
    return this.plz;
  }

  get getCity() {
    return this.city;
  }

  get getStreet() {
    return this.street;
  }

  get getNr() {
    return this.nr;
  }

  get getCompanyForm() {
    return this.companyFormValid;
  }
}

export const companyModule = new CompanyModule({store, name: "company"});



