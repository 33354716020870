
import { Options, Vue } from "vue-class-component";
import BaseInput from "@/components/base/base-input.vue";
import BaseButton from "@/components/base/base-button.vue";
import BaseLabel from "@/components/base/base-label.vue";
import { VueRecaptcha } from "vue-recaptcha";
import { resultModule } from "@/store/register/result";
import { userModule } from "@/store/register/user";
import { companyModule } from "@/store/register/company";
import { contactModule } from "@/store/register/contact";
import { userStorageModule } from "@/store/user-storage";

@Options({
  components: {
    BaseInput,
    BaseButton,
    BaseLabel,
    VueRecaptcha,
  },
  emits: ["change-route", "success"],
})
export default class RegisterStepperResult extends Vue {
  password = { value: "", isValid: true };
  passwordConfirm = { value: "", isValid: true };
  siteKey = "6LfEtm8fAAAAADdiiRJJpoH1-_c-94Z87aQHKx7l";
  captchaValid = { value: false, isValid: true };

  backToContact() {
    this.$emit("change-route", "contact");
  }

  onVerify(response: string) {
    if (response) {
      this.captchaValid.value = true;
    }
  }

  onExpired() {
    this.captchaValid.value = false;
  }

  validateCaptcha() {
    this.captchaValid.isValid = this.captchaValid.value;
  }

  validatePassword() {
    this.password.isValid =
      this.password.value.trim() !== "" &&
      this.password.value.length > 7 &&
      /[A-Z]/.test(this.password.value) &&
      /[a-z]/.test(this.password.value) &&
      /\d/.test(this.password.value);
  }

  validatePasswordConfirm() {
    this.passwordConfirm.isValid =
      this.passwordConfirm.value.trim() !== "" &&
      this.passwordConfirm.value.length > 7 &&
      this.password.value === this.passwordConfirm.value &&
      /[A-Z]/.test(this.password.value) &&
      /[a-z]/.test(this.password.value) &&
      /\d/.test(this.password.value);
  }

  submitResult() {
    this.validatePassword();
    this.validatePasswordConfirm();
    this.validateCaptcha();

    if (
      this.password.isValid &&
      this.passwordConfirm.isValid &&
      this.captchaValid.isValid
    ) {
      resultModule.actionSetPassword(this.password.value);
      resultModule.actionSetPasswordConfirm(this.passwordConfirm.value);
      resultModule.actionSetCaptcha(this.captchaValid.value);
      resultModule.actionSetResultForm(true);
      if (
        userModule.getUserForm &&
        companyModule.getCompanyForm &&
        contactModule.getContactForm &&
        resultModule.getResultForm
      ) {
        userStorageModule.actionSetUser({
          username: userModule.getUsername,
          company: companyModule.getCompany,
          co: companyModule.getCo,
          land: companyModule.getLand,
          plz: companyModule.getPlz,
          city: companyModule.getCity,
          street: companyModule.getStreet,
          nr: companyModule.getNr,
          salutation: contactModule.getSalutation,
          title: contactModule.getTitle,
          firstName: contactModule.getFirstName,
          lastName: contactModule.getLastName,
          position: contactModule.getPosition,
          phone: contactModule.getPhone,
          email: contactModule.getEmail,
          password: resultModule.getPassword,
        });
        console.log("RERERERER")
        this.$emit("change-route", "success");
      }
    }
  }

  mounted() {
    resultModule.setResultForm(false);
    this.password.value = resultModule.getPassword;
    this.passwordConfirm.value = resultModule.getPasswordConfirm;
    this.captchaValid.value = resultModule.getCaptcha;
  }
}
