
import {Options, Vue} from "vue-class-component";
import CartTable from "@/components/cart/cart-table.vue";
import BaseHeader from "@/components/base/base-header.vue";
import {cartModule} from "@/store/cart/cart";

@Options({
  components: {
    BaseHeader,
    CartTable
  }
})
export default class LeiCart extends Vue {
  items = cartModule.getCartItems;

  itemsInCart(){
    return this.items.length + " LEIs im Warenkorb";
  }
}
