import {VuexModule, Module, Mutation, Action} from "vuex-class-modules";
import store from '@/store';


@Module
export class RequestResultModule extends VuexModule {

  company: string[] = [];
  cart: {
    company: string,
    regId: string,
    taxId: string,
    plz: string,
    city: string,
    street: string,
    nr: string,
    juPlz: string,
    juCity: string,
    juStreet: string,
    juNr: string,
    parentCompany: string,
    parentRegId: string,
    parentPlz: string,
    parentCity: string,
    parentStreet: string,
    parentNr: string,
    mainCompany: string,
    mainRegId: string,
    mainPlz: string,
    mainCity: string,
    mainStreet: string,
    mainNr: string,
  } [] = [];

  @Mutation
  setCart(data: {
    company: string,
    regId: string,
    taxId: string,
    plz: string,
    city: string,
    street: string,
    nr: string,
    juPlz: string,
    juCity: string,
    juStreet: string,
    juNr: string,
    parentCompany: string,
    parentRegId: string,
    parentPlz: string,
    parentCity: string,
    parentStreet: string,
    parentNr: string,
    mainCompany: string,
    mainRegId: string,
    mainPlz: string,
    mainCity: string,
    mainStreet: string,
    mainNr: string,
  }) {
    this.cart.push(data);
  }

  @Mutation
  setCompany(company: string) {
    if (this.company.length === this.cart.length) {
      this.company.push(company);
    } else {
      this.company.pop();
      this.company.push(company);
    }
  }

  @Action
  actionSetCart(data: {
    company: string,
    regId: string,
    taxId: string,
    plz: string,
    city: string,
    street: string,
    nr: string,
    juPlz: string,
    juCity: string,
    juStreet: string,
    juNr: string,
    parentCompany: string,
    parentRegId: string,
    parentPlz: string,
    parentCity: string,
    parentStreet: string,
    parentNr: string,
    mainCompany: string,
    mainRegId: string,
    mainPlz: string,
    mainCity: string,
    mainStreet: string,
    mainNr: string,
  }) {
    this.setCart(data);
  }

  @Action
  actionSetCompany(company: string) {
    this.setCompany(company);
  }

  get getCart() {
    return this.cart;
  }

  get getCompany() {
    return this.company;
  }

  get getCounter() {
    return this.company.length;
  }

  get getCartCounter() {
    return this.cart.length;
  }
}

export const requestResultModule = new RequestResultModule({store, name: "request-result"});
