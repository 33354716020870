import {VuexModule, Module, Mutation, Action} from "vuex-class-modules";
import store from '@/store';

@Module
export class AddressParentModule extends VuexModule {

  value = true;
  parentPlz = '';
  parentCity = '';
  parentStreet = '';
  parentNr = '';
  mainPlz = '';
  mainCity = '';
  mainStreet = '';
  mainNr = '';
  addressFormValid = false;

  @Mutation
  reset() {
    this.value = true;
    this.parentPlz = '';
    this.parentCity = '';
    this.parentStreet = '';
    this.parentNr = '';
    this.mainPlz = '';
    this.mainCity = '';
    this.mainStreet = '';
    this.mainNr = '';
    this.addressFormValid = false;
  }

  @Mutation
  setValue(value: boolean) {
    this.value = value;
  }

  @Mutation
  setParentPlz(plz: string) {
    this.parentPlz = plz;
  }

  @Mutation
  setParentCity(city: string) {
    this.parentCity = city;
  }

  @Mutation
  setParentStreet(street: string) {
    this.parentStreet = street;
  }

  @Mutation
  setParentNr(nr: string) {
    this.parentNr = nr;
  }

  @Mutation
  setMainPlz(plz: string) {
    this.mainPlz = plz;
  }

  @Mutation
  setMainCity(city: string) {
    this.mainCity = city;
  }

  @Mutation
  setMainStreet(street: string) {
    this.mainStreet = street;
  }

  @Mutation
  setMainNr(nr: string) {
    this.mainNr = nr;
  }

  @Mutation
  setAddressForm(valid: boolean) {
    this.addressFormValid = valid;
  }

  @Action
  actionReset() {
    this.reset();
  }

  @Action
  actionSetValue(value: boolean) {
    this.setValue(value);
  }

  @Action
  actionSetParentPlz(plz: string) {
    this.setParentPlz(plz);
  }

  @Action
  actionSetParentCity(city: string) {
    this.setParentCity(city);
  }

  @Action
  actionSetParentStreet(street: string) {
    this.setParentStreet(street);
  }

  @Action
  actionSetParentNr(nr: string) {
    this.setParentNr(nr);
  }

  @Action
  actionSetMainPlz(plz: string) {
    this.setMainPlz(plz);
  }

  @Action
  actionSetMainCity(city: string) {
    this.setMainCity(city);
  }

  @Action
  actionSetMainStreet(street: string) {
    this.setMainStreet(street);
  }

  @Action
  actionSetMainNr(nr: string) {
    this.setMainNr(nr);
  }

  @Action
  actionSetAddressForm(valid: boolean) {
    this.setAddressForm(valid);
  }

  get getValue() {
    return this.value;
  }

  get getParentPlz() {
    return this.parentPlz;
  }

  get getParentCity() {
    return this.parentCity;
  }

  get getParentStreet() {
    return this.parentStreet;
  }

  get getParentNr() {
    return this.parentNr;
  }

  get getMainPlz() {
    return this.mainPlz;
  }

  get getMainCity() {
    return this.mainCity;
  }

  get getMainStreet() {
    return this.mainStreet;
  }

  get getMainNr() {
    return this.mainNr;
  }

  get getAddressForm() {
    return this.addressFormValid;
  }

}

export const addressParentModule = new AddressParentModule({store, name: "address-parent"});
