
import { Options, Vue } from "vue-class-component";
import { cartModule } from "@/store/cart/cart";
import { leiStorageModule } from "@/store/lei-storage";
import BaseButton from "@/components/base/base-button.vue";
import router from "@/router";

@Options({
  components: {
    BaseButton,
  },
})
export default class CartTable extends Vue {
  items = cartModule.getCartItems;
  types = cartModule.getTypes;
  price = 65;
  sum = this.items.length * this.price;

  buy() {
    for (let i = 0; i < this.items.length; i++) {
      if (this.types[i] === "extension") {
        let date = this.items[i].registrationDetails.nextRenewal;
        date.setFullYear(date.getFullYear() + 1);
        this.items[i].registrationDetails.nextRenewal = date;

        leiStorageModule.actionUpdateLei(this.items[i]);
      } else if (this.types[i] === "request") {
        leiStorageModule.addLei(this.items[i]);
      }
    }
    cartModule.actionDeleteCart();
    this.showDashboard();
  }

  deleteItem(id: string) {
    this.sum -= this.price;
    cartModule.deleteCartItem(id);
  }

  showDashboard() {
    router.push({ path: "/dashboard" });
  }

  showDetails(id: string) {
    router.push({ path: "/cart/" + id });
  }
}
