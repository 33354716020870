
import {Vue} from 'vue-class-component';
import {Prop} from 'vue-property-decorator';

export default class SelectorTitle extends Vue {

  placeholder = 'Bitte wählen';
  titles = [
    'B.Sc.',
    'BBA',
    'Betriebswirt (VWA)',
    'Dipl.-Ing. Dr.',
    'Dipl.-Betriebswirt',
    'Dipl.-Betriebswirt (FH)',
    'Dipl.-Betriebswirtin',
    'Dipl.-Des.',
    'Dipl.-Inform.',
    'Dipl.-Inform. (FH)',
    'Dipl.-Ing.',
    'Dipl.-Ing. (FH)',
    'Dipl.-Kffr.',
    'Dipl.-Kfm.',
    'Dipl.-Logist.',
    'Dipl.-Math.',
    'Dipl.-Ök.',
    'Dipl.-Phys.',
    'Dipl.-Psychologe',
    'Dipl.-Soz. Wiss.',
    'Dipl.-Übers.',
    'Dipl.-Verwaltungswirt',
    'Dipl.-Volkswirt',
    'Dipl.-Volkswirt (FH)',
    'Dipl.-Wirtsch.-Inf.',
    'Dipl.-Wirtsch.-Inf. (BA)',
    'Dipl.-Wirtsch.-Ing.',
    'Dipl.-Wirtsch.-Ing. (FH)',
    'Dr.',
    'Dr. Dr.',
    'Dr. h. c.',
    'Dr. jur.',
    'Dr. med.',
    'Dr. Ök.',
    'Dr. rer. nat.',
    'Dr.-Ing.',
    'Ing.',
    'M. A.',
    'M. Sc.',
    'Mag.',
    'MIB',
    'MIM',
    'MMag.',
    'Prof.',
    'Prof. Dr.',
    'Prof. Dr. Dr.',
    'Prof. Dr. Dr. Dr. h. c.',
    'Prof. Dr. h. c.',
  ];

  @Prop() modelValue!: string
  @Prop() onBlur!: string
  @Prop() valid!: boolean

  onChange(event: string) {
    this.$emit("update:modelValue", event);
  }
}
