import {VuexModule, Module, Mutation, Action} from "vuex-class-modules";
import store from '@/store';

@Module
export class RequestParentModule extends VuexModule {
  parentCompany = '';
  parentRegId = '';
  mainCompany = '';
  mainRegId = '';
  value1 = false;
  value2 = false;
  value3 = true;
  parentPlz = '';
  parentCity = '';
  parentStreet = '';
  parentNr = '';
  mainPlz = '';
  mainCity = '';
  mainStreet = '';
  mainNr = '';
  parentFormValid = false;

  @Mutation
  reset() {
    this.parentCompany = '';
    this.parentRegId = '';
    this.mainCompany = '';
    this.mainRegId = '';
    this.value1 = false;
    this.value2 = false;
    this.value3 = true;
    this.parentPlz = '';
    this.parentCity = '';
    this.parentStreet = '';
    this.parentNr = '';
    this.mainPlz = '';
    this.mainCity = '';
    this.mainStreet = '';
    this.mainNr = '';
    this.parentFormValid = false;
  }

  @Mutation
  setParentCompany(company: string) {
    this.parentCompany = company;
  }

  @Mutation
  setParentRegId(regId: string) {
    this.parentRegId = regId;
  }

  @Mutation
  setMainCompany(company: string) {
    this.mainCompany = company;
  }

  @Mutation
  setMainRegId(regId: string) {
    this.mainRegId = regId;
  }

  @Mutation
  setParentForm(valid: boolean) {
    this.parentFormValid = valid;
  }

  @Mutation
  setValue1(value: boolean) {
    this.value1 = value;
  }

  @Mutation
  setValue2(value: boolean) {
    this.value2 = value;
  }

  @Mutation
  setValue3(value: boolean) {
    this.value3 = value;
  }

  @Mutation
  setParentPlz(plz: string) {
    this.parentPlz = plz;
  }

  @Mutation
  setParentCity(city: string) {
    this.parentCity = city;
  }

  @Mutation
  setParentStreet(street: string) {
    this.parentStreet = street;
  }

  @Mutation
  setParentNr(nr: string) {
    this.parentNr = nr;
  }

  @Mutation
  setMainPlz(plz: string) {
    this.mainPlz = plz;
  }

  @Mutation
  setMainCity(city: string) {
    this.mainCity = city;
  }

  @Mutation
  setMainStreet(street: string) {
    this.mainStreet = street;
  }

  @Mutation
  setMainNr(nr: string) {
    this.mainNr = nr;
  }

  @Action
  actionReset() {
    this.reset();
  }

  @Action
  actionSetParentCompany(company: string) {
    this.setParentCompany(company);
  }

  @Action
  actionSetParentRegId(regId: string) {
    this.setParentRegId(regId);
  }

  @Action
  actionSetMainCompany(company: string) {
    this.setMainCompany(company);
  }

  @Action
  actionSetMainRegId(regId: string) {
    this.setMainRegId(regId);
  }

  @Action
  actionSetParentForm(valid: boolean) {
    this.setParentForm(valid);
  }

  @Action
  actionSetParentPlz(plz: string) {
    this.setParentPlz(plz);
  }

  @Action
  actionSetParentCity(city: string) {
    this.setParentCity(city);
  }

  @Action
  actionSetParentStreet(street: string) {
    this.setParentStreet(street);
  }

  @Action
  actionSetParentNr(nr: string) {
    this.setParentNr(nr);
  }

  @Action
  actionSetMainPlz(plz: string) {
    this.setMainPlz(plz);
  }

  @Action
  actionSetMainCity(city: string) {
    this.setMainCity(city);
  }

  @Action
  actionSetMainStreet(street: string) {
    this.setMainStreet(street);
  }

  @Action
  actionSetMainNr(nr: string) {
    this.setMainNr(nr);
  }

  @Action
  actionSetValue1(value: boolean) {
    this.setValue1(value);
  }

  @Action
  actionSetValue2(value: boolean) {
    this.setValue2(value);
  }

  @Action
  actionSetValue3(value: boolean) {
    this.setValue3(value);
  }

  get getParentCompany() {
    return this.parentCompany;
  }

  get getParentRegId() {
    return this.parentRegId;
  }

  get getMainCompany() {
    return this.mainCompany;
  }

  get getMainRegId() {
    return this.mainRegId;
  }

  get getParentPlz() {
    return this.parentPlz;
  }

  get getParentCity() {
    return this.parentCity;
  }

  get getParentStreet() {
    return this.parentStreet;
  }

  get getParentNr() {
    return this.parentNr;
  }

  get getMainPlz() {
    return this.mainPlz;
  }

  get getMainCity() {
    return this.mainCity;
  }

  get getMainStreet() {
    return this.mainStreet;
  }

  get getMainNr() {
    return this.mainNr;
  }

  get getParentForm() {
    return this.parentFormValid;
  }

  get getValue1() {
    return this.value1;
  }

  get getValue2() {
    return this.value2;
  }

  get getValue3() {
    return this.value3;
  }
}

export const requestParentModule = new RequestParentModule({
  store,
  name: "request-parent-company"
});



