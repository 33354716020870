import { toDisplayString as _toDisplayString, vShow as _vShow, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0f64d554"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["aria-checked"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", {
    class: "vue-toggles",
    style: _normalizeStyle(_ctx.bgStyle),
    role: "switch",
    tabindex: "0",
    "aria-checked": _ctx.value ? 'true' : 'false',
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleButton && _ctx.toggleButton(...args)))
  }, [
    _createElementVNode("span", {
      "aria-hidden": "true",
      style: _normalizeStyle(_ctx.dotStyle),
      class: "dot"
    }, [
      _withDirectives(_createElementVNode("span", {
        style: _normalizeStyle(_ctx.textStyle),
        class: "text"
      }, _toDisplayString(_ctx.checkedText), 5), [
        [_vShow, _ctx.value]
      ]),
      _withDirectives(_createElementVNode("span", {
        style: _normalizeStyle(_ctx.textStyle),
        class: "text"
      }, _toDisplayString(_ctx.uncheckedText), 5), [
        [_vShow, !_ctx.value]
      ])
    ], 4)
  ], 12, _hoisted_1))
}