import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0966625c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "form-control" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = {
  key: 2,
  style: {color: 'green'}
}
const _hoisted_5 = { class: "buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_label = _resolveComponent("base-label")!
  const _component_base_input = _resolveComponent("base-input")!
  const _component_base_button = _resolveComponent("base-button")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (!_ctx.input.isValid)
        ? (_openBlock(), _createElementBlock("p", _hoisted_2, " Benutzername darf nicht leer sein"))
        : (_ctx.usernameTaken.value && _ctx.usernameTaken.active)
          ? (_openBlock(), _createElementBlock("p", _hoisted_3, " Benutzername schon vorhanden"))
          : (!_ctx.usernameTaken.value && _ctx.usernameTaken.active)
            ? (_openBlock(), _createElementBlock("p", _hoisted_4, " Benutzername verfügbar"))
            : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_base_label, { title: 'Benutzername*' })
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_base_input, {
        width: '300px',
        modelValue: _ctx.input.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.input.value) = $event)),
        valid: _ctx.input.isValid,
        onBlur: _ctx.validateUsername
      }, null, 8, ["modelValue", "valid", "onBlur"]),
      _createVNode(_component_base_button, {
        title: 'Verfügbarkeit prüfen',
        onClick: _ctx.checkUsername,
        class: "availability"
      }, null, 8, ["onClick"])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_base_button, {
        disabledButton: true,
        title: 'Zurück'
      }),
      _createVNode(_component_base_button, {
        title: 'Weiter',
        onClick: _ctx.setUsername
      }, null, 8, ["onClick"])
    ])
  ], 64))
}