
import {Vue} from "vue-class-component";
import {Prop} from 'vue-property-decorator';

export default class BaseButton extends Vue {

  @Prop() title!: string
  @Prop() disabledButton!: boolean
  @Prop() onClick!: string

  get text() {
    return this.title;
  }
}
