import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_lei_details = _resolveComponent("lei-details")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_lei_details, {
      "back-uri": "dashboard",
      lei: _ctx.lei
    }, null, 8, ["lei"])
  ]))
}