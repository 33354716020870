<template>
  <base-navmenu></base-navmenu>
  <router-view></router-view>
  <base-footer></base-footer>
</template>

<script>
import BaseFooter from "@/components/base/base-footer";
import BaseNavmenu from "@/components/base/base-navmenu";

export default {
  components: {BaseFooter, BaseNavmenu}
}
</script>

<style>
@import "./assets/App.css";

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  overflow-x: hidden;
  overflow-y: auto;
  min-height: 100vh;
  position: relative;
}
</style>