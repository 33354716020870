import {VuexModule, Module, Mutation, Action} from "vuex-class-modules";
import store from '@/store';

@Module
export class ContactModule extends VuexModule {

  salutation = '';
  title = '';
  firstName = '';
  lastName = '';
  position = '';
  phone = '';
  email = '';
  emailConfirm = '';
  contactFormValid = false;

  @Mutation
  setSalutation(salutation: string) {
    this.salutation = salutation;
  }

  @Mutation
  setTitle(title: string) {
    this.title = title;
  }

  @Mutation
  setFirstName(firstName: string) {
    this.firstName = firstName;
  }

  @Mutation
  setLastName(lastName: string) {
    this.lastName = lastName;
  }

  @Mutation
  setPosition(position: string) {
    this.position = position;
  }

  @Mutation
  setPhone(phone: string) {
    this.phone = phone;
  }

  @Mutation
  setEmail(email: string) {
    this.email = email;
  }

  @Mutation
  setEmailConfirm(emailConfirm: string) {
    this.emailConfirm = emailConfirm;
  }

  @Mutation
  setContactForm(valid: boolean) {
    this.contactFormValid = valid;
  }

  @Action
  actionSetSalutation(salutation: string) {
    this.setSalutation(salutation);
  }

  @Action
  actionSetTitle(title: string) {
    this.setTitle(title);
  }

  @Action
  actionSetFirstName(firstName: string) {
    this.setFirstName(firstName);
  }

  @Action
  actionSetLastName(lastName: string) {
    this.setLastName(lastName);
  }

  @Action
  actionSetPosition(position: string) {
    this.setPosition(position);
  }

  @Action
  actionSetPhone(phone: string) {
    this.setPhone(phone);
  }

  @Action
  actionSetEmail(email: string) {
    this.setEmail(email);
  }

  @Action
  actionSetEmailConfirm(emailConfirm: string) {
    this.setEmailConfirm(emailConfirm);
  }

  @Action
  actionSetContactForm(valid: boolean) {
    this.setContactForm(valid);
  }

  get getSalutation() {
    return this.salutation;
  }

  get getTitle() {
    return this.title;
  }

  get getFirstName() {
    return this.firstName;
  }

  get getLastName() {
    return this.lastName;
  }

  get getPosition() {
    return this.position;
  }

  get getPhone() {
    return this.phone;
  }

  get getEmail() {
    return this.email;
  }

  get getEmailConfirm() {
    return this.emailConfirm;
  }

  get getContactForm() {
    return this.contactFormValid;
  }
}

export const contactModule = new ContactModule({store, name: "contact"});
