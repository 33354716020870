import {VuexModule, Module, Mutation, Action} from "vuex-class-modules";
import store from '@/store';

@Module
export class SearchModule extends VuexModule {
  items!: { lei: string, name: string, js: { street: string, city: string, country: string }, vs: { street: string, city: string, country: string }, status: string }[];
  amount!: string;
  resultReady = false;

  @Mutation
  addItem(item: { lei: string, name: string, js: { street: string, city: string, country: string }, vs: { street: string, city: string, country: string }, status: string }) {
    this.items.push(item);
    this.resultReady = true;
  }

  @Mutation
  setAmount(amount: string) {
    this.amount = amount;
  }

  @Mutation
  setResult(result: boolean) {
    this.resultReady = result;
  }

  @Mutation
  clearItems() {
    this.items = [];
    this.resultReady = false;
  }

  @Action
  actionAddItem(item: { lei: string, name: string, js: { street: string, city: string, country: string }, vs: { street: string, city: string, country: string }, status: string }) {
    this.addItem(item);
  }

  @Action
  actionSetAmount(amount: string) {
    this.setAmount(amount);
  }

  @Action
  actionSetResult(result: boolean) {
    this.setResult(result);
  }

  @Action
  actionClearItems() {
    this.clearItems();
  }

  get getResult() {
    return this.resultReady;
  }

  get getItems() {
    return this.items;
  }

  get getAmount() {
    return this.amount;
  }
}

export const searchModule = new SearchModule({store, name: "search"});
