
import { Options, Vue } from "vue-class-component";
import RegisterOutlineHeader from "@/components/register/outline/register-outline-header.vue";
import RegisterStepper from "@/components/register/stepper/register-stepper.vue";
import BaseFooter from "@/components/base/base-footer.vue";

@Options({
  components: {
    RegisterOutlineHeader,
    RegisterStepper,
    BaseFooter,
  },
})
export default class LeiRegister extends Vue {
  heading = "Benutzername";

  changeHeading(step: string) {
    switch (step) {
      case "user":
        this.heading = "Benutzername";
        break;
      case "company":
        this.heading = "Unternehmen";
        break;
      case "contact":
        this.heading = "Ansprechpartner";
        break;
      case "result":
        this.heading = "Abschluss";
        break;
      case "success":
        this.heading = "Erfolgreich angelegt";
        break;
      default:
        this.heading = "";
    }
  }
}
