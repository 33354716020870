import store from "@/store/index";
import { Action, Module, Mutation, VuexModule } from "vuex-class-modules";
import { Subscription } from "./model";

@Module
export class SubscriptionStorageModule extends VuexModule {
  subscriptions: Subscription[] = [
    {
      id: "1",
      duration: "1 Jahr",
      durationYears: 1,
      price: 69,
    },
    {
      id: "2",
      duration: "3 Jahre",
      durationYears: 3,
      price: 55,
    },
    {
      id: "3",
      duration: "5 Jahre",
      durationYears: 5,
      price: 50,
    },
    {
      id: "4",
      duration: "Lifetime",
      durationYears: Infinity,
      price: 59,
    },
  ];

  @Mutation
  updateSubscription(subscription: Subscription) {
    const index = this.subscriptions
      .map((oldSubscription) => oldSubscription.id)
      .indexOf(subscription.id);
    this.subscriptions.splice(index, 1, subscription);
  }

  @Action
  update(subscription: Subscription) {
    this.updateSubscription(subscription);
  }

  get getSubscriptions() {
    return this.subscriptions;
  }
}

export const subscriptionStorageModule = new SubscriptionStorageModule({
  store,
  name: "subscription-storage",
});
