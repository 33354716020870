import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-66ecb4eb"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "container-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_register_outline_header = _resolveComponent("register-outline-header")!
  const _component_register_stepper = _resolveComponent("register-stepper")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("header", null, [
      _createVNode(_component_register_outline_header, { "step-heading": _ctx.heading }, null, 8, ["step-heading"])
    ]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_register_stepper, { onStepChange: _ctx.changeHeading }, null, 8, ["onStepChange"])
      ])
    ])
  ], 64))
}