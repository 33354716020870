import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-47cb9a2c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "form-control" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "label" }
const _hoisted_5 = { class: "input" }
const _hoisted_6 = { class: "label" }
const _hoisted_7 = { class: "input" }
const _hoisted_8 = { class: "label" }
const _hoisted_9 = { class: "input" }
const _hoisted_10 = { class: "label" }
const _hoisted_11 = { class: "input" }
const _hoisted_12 = { class: "label" }
const _hoisted_13 = { class: "input" }
const _hoisted_14 = { class: "buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_label = _resolveComponent("base-label")!
  const _component_selector_salutation = _resolveComponent("selector-salutation")!
  const _component_selector_title = _resolveComponent("selector-title")!
  const _component_base_input = _resolveComponent("base-input")!
  const _component_base_button = _resolveComponent("base-button")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (!_ctx.salutation.isValid || !_ctx.firstName.isValid || !_ctx.lastName.isValid || !_ctx.position.isValid || !_ctx.phone.isValid)
        ? (_openBlock(), _createElementBlock("p", _hoisted_2, " Daten überprüfen"))
        : (!_ctx.emailConfirm.isValid || !_ctx.email.isValid)
          ? (_openBlock(), _createElementBlock("p", _hoisted_3, " E-Mail Adresse überprüfen"))
          : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_base_label, { title: 'Anrede*' }),
      _createVNode(_component_base_label, {
        margin: '323px',
        title: 'Titel'
      })
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_selector_salutation, {
        modelValue: _ctx.salutation.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.salutation.value) = $event)),
        valid: _ctx.salutation.isValid,
        onBlur: _ctx.validateSalutation
      }, null, 8, ["modelValue", "valid", "onBlur"]),
      _createVNode(_component_selector_title, {
        modelValue: _ctx.title.value,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.title.value) = $event)),
        valid: _ctx.title.isValid,
        onBlur: _ctx.validateTitle
      }, null, 8, ["modelValue", "valid", "onBlur"])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_base_label, { title: 'Vorname*' }),
      _createVNode(_component_base_label, {
        margin: '315px',
        title: 'Nachname*'
      })
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createVNode(_component_base_input, {
        modelValue: _ctx.firstName.value,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.firstName.value) = $event)),
        valid: _ctx.firstName.isValid,
        onBlur: _ctx.validateFirstName,
        width: '300px'
      }, null, 8, ["modelValue", "valid", "onBlur"]),
      _createVNode(_component_base_input, {
        modelValue: _ctx.lastName.value,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.lastName.value) = $event)),
        valid: _ctx.lastName.isValid,
        onBlur: _ctx.validateLastName,
        width: '300px'
      }, null, 8, ["modelValue", "valid", "onBlur"])
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createVNode(_component_base_label, { title: 'Funktion*' })
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createVNode(_component_base_input, {
        modelValue: _ctx.position.value,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.position.value) = $event)),
        valid: _ctx.position.isValid,
        onBlur: _ctx.validatePosition,
        width: '300px'
      }, null, 8, ["modelValue", "valid", "onBlur"])
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createVNode(_component_base_label, { title: 'Telefon*' })
    ]),
    _createElementVNode("div", _hoisted_11, [
      _createVNode(_component_base_input, {
        type: 'tel',
        modelValue: _ctx.phone.value,
        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.phone.value) = $event)),
        valid: _ctx.phone.isValid,
        onBlur: _ctx.validatePhone,
        width: '300px'
      }, null, 8, ["modelValue", "valid", "onBlur"])
    ]),
    _createElementVNode("div", _hoisted_12, [
      _createVNode(_component_base_label, { title: 'E-Mail*' }),
      _createVNode(_component_base_label, {
        margin: '333px',
        title: 'E-Mail wiederholen*'
      })
    ]),
    _createElementVNode("div", _hoisted_13, [
      _createVNode(_component_base_input, {
        type: 'email',
        modelValue: _ctx.email.value,
        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.email.value) = $event)),
        valid: _ctx.email.isValid,
        onBlur: _ctx.validateEmail,
        width: '300px'
      }, null, 8, ["modelValue", "valid", "onBlur"]),
      _createVNode(_component_base_input, {
        type: 'email',
        modelValue: _ctx.emailConfirm.value,
        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.emailConfirm.value) = $event)),
        valid: _ctx.emailConfirm.isValid,
        onBlur: _ctx.validateEmailConfirm,
        width: '300px'
      }, null, 8, ["modelValue", "valid", "onBlur"])
    ]),
    _createElementVNode("div", _hoisted_14, [
      _createVNode(_component_base_button, {
        title: 'Zurück',
        onClick: _ctx.backToCompany
      }, null, 8, ["onClick"]),
      _createVNode(_component_base_button, {
        title: 'Weiter',
        onClick: _ctx.submitContact
      }, null, 8, ["onClick"])
    ])
  ], 64))
}