import store from '@/store/index';
import {Action, Module, Mutation, VuexModule} from 'vuex-class-modules';
import {userModule} from '@/store/register/user';
import { User } from './model';

@Module
export class UserStorageModule extends VuexModule {
  users: User[] = [{
    id: '0',
    username: 'admin',
    company: '',
    co: '',
    land: '',
    plz: '',
    city: '',
    street: '',
    nr: '',
    salutation: '',
    title: '',
    firstName: '',
    lastName: '',
    position: '',
    phone: '',
    email: '',
    password: '',
    subscription: undefined,
    subscriptionPrice: undefined,
    subscriptionPriceStart: undefined,
    subscriptionPriceDuration: undefined,
  },
  {
    id: '1',
    username: 'karl_adesso',
    company: 'adesso SE',
    co: 'adesso',
    land: 'Deutschland',
    plz: '44269',
    city: 'Dortmund',
    street: 'Adessoplatz',
    nr: '1',
    salutation: 'Herr',
    title: '',
    firstName: 'Karl',
    lastName: 'Müller',
    position: 'Senior Software Engineer',
    phone: '0176 123456',
    email: 'karl.mueller@adesso.de',
    password: 'Asdf1234',
    subscription: "2",
    subscriptionPrice: undefined,
    subscriptionPriceStart: new Date("2022-10-08"),
    subscriptionPriceDuration: 12,
  },
  {
    id: '2',
    username: 'emma_ais',
    company: 'adesso insurance solutions GmbH',
    co: 'adesso',
    land: 'Deutschland',
    plz: '44269',
    city: 'Dortmund',
    street: 'Adessoplatz',
    nr: '1',
    salutation: 'Frau',
    title: '',
    firstName: 'Emma',
    lastName: 'Meier',
    position: 'Senior Consultant',
    phone: '0176 654321',
    email: 'emma.meier@adesso.de',
    password: 'Asdf1234',
    subscription: "4",
    subscriptionPrice: 45,
    subscriptionPriceStart: undefined,
    subscriptionPriceDuration: undefined,
  },
  {
    id: '3',
    username: 'nadja_ams',
    company: 'adesso mobile solutions GmbH',
    co: 'adesso',
    land: 'Deutschland',
    plz: '50678',
    city: 'Köln',
    street: 'Agrippinawerft 26',
    nr: '26',
    salutation: 'Frau',
    title: 'Dr.',
    firstName: 'Nadja',
    lastName: 'Kreisel',
    position: 'Senior Architect',
    phone: '0143 658791',
    email: 'nadja.kreisel@adesso.de',
    password: 'Asdf1234',
    subscription: undefined,
    subscriptionPrice: undefined,
    subscriptionPriceStart: undefined,
    subscriptionPriceDuration: undefined,

  },
];


  @Mutation
  setUser(user: User) {
    this.users.push(user);
  }

  @Action
  actionSetUser(user: {
    username: string,
    company: string,
    co: string,
    land: string,
    plz: string,
    city: string,
    street: string,
    nr: string,
    salutation: string,
    title: string,
    firstName: string,
    lastName: string,
    position: string,
    phone: string,
    email: string,
    password: string,
    subscription?: string,
    subscriptionPrice?: number,
  }) {
    this.setUser({
      id: this.getUsers.length.toString(),
      username: user.username,
      company: user.company,
      co: user.co,
      land: user.land,
      plz: user.plz,
      city: user.city,
      street: user.street,
      nr: user.nr,
      salutation: user.salutation,
      title: user.title,
      firstName: user.firstName,
      lastName: user.lastName,
      position: user.position,
      phone: user.phone,
      email: user.email,
      password: user.password,
      subscription: user.subscription,
      subscriptionPrice: user.subscriptionPrice,
    });
  }

  @Mutation
  updateUser(user: User) {
    const index = this.users
      .map((oldUser) => oldUser.id)
      .indexOf(user.id);
    this.users.splice(index, 1, user);
  }

  @Action
  update(user: User) {
    this.updateUser(user);
  }


  get getUsers() {
    return this.users;
  }

  get checkUsername() {
    return this.users.some(x => x.username === userModule.username);
  }
}

export const userStorageModule = new UserStorageModule({store, name: "user-storage"});
