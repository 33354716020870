
import {Options, Vue} from "vue-class-component";
import RequestStepperHeader from '@/components/request/request-stepper-header.vue';
import RequestStepperCompany from '@/components/request/request-stepper-company.vue';
import RequestStepperParent from '@/components/request/request-stepper-parent.vue';
import RequestStepperAddress from '@/components/request/request-stepper-address.vue';
import RequestStepperResult from '@/components/request/request-stepper-result.vue';

@Options({
  components: {
    RequestStepperHeader,
    RequestStepperCompany,
    RequestStepperAddress,
    RequestStepperParent,
    RequestStepperResult
  },
  emits: ['stepChange'],
})
export default class RequestStepper extends Vue {
  currentRoute = 'company';

  public setRoute(route: string) {
    this.currentRoute = route;
    this.$emit("stepChange", this.currentRoute);
  }
}
