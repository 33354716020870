
import {Options, Vue} from "vue-class-component";
import BaseButton from '@/components/base/base-button.vue';
import BaseInput from '@/components/base/base-input.vue';
import BaseAutocomplete from '@/components/base/base-autocomplete.vue';
import BaseLabel from '@/components/base/base-label.vue';
import {addressModule} from '@/store/request/address';
import {Prop} from 'vue-property-decorator';
import {addressParentModule} from '@/store/request/address-parent';

@Options({
  components: {
    BaseButton,
    BaseInput,
    BaseAutocomplete,
    BaseLabel
  },
  emits: ['valid-form','interface']
})
export default class RequestBaseAddress extends Vue {

  @Prop() type!: string;

  plz = {value: '', isValid: true};
  city = {value: '', isValid: true};
  street = {value: '', isValid: true};
  nr = {value: '', isValid: true};
  code = 'countryCode:DEU';

  // eslint-disable-next-line
  autocompleteData: any[] = [];
  // eslint-disable-next-line
  autocompletePlzData: any[] = [];
  // eslint-disable-next-line
  autocompleteCityData: any[] = [];
  // eslint-disable-next-line
  autocompleteStreetData: any[] = [];
  // eslint-disable-next-line
  autocompleteNrData: any[] = [];
  apiKey = 'cn9bewzqlYNTFyQhy7Hky3O64MzA5xinLuACfHzccEU';


  mounted() {
    if (this.type === 'stepper-address') {
      this.plz.value = addressModule.getPlz;
      this.city.value = addressModule.getCity;
      this.street.value = addressModule.getStreet;
      this.nr.value = addressModule.getNr;
    }
    if (this.type === 'stepper-ju-address') {
      this.plz.value = addressModule.getJuPlz;
      this.city.value = addressModule.getJuCity;
      this.street.value = addressModule.getJuStreet;
      this.nr.value = addressModule.getJuNr;
    }
    if (this.type === 'stepper-parent-address') {
      this.plz.value = addressParentModule.getParentPlz;
      this.city.value = addressParentModule.getParentCity;
      this.street.value = addressParentModule.getParentStreet;
      this.nr.value = addressParentModule.getParentNr;
    }
    if (this.type === 'stepper-main-address') {
      this.plz.value = addressParentModule.getMainPlz;
      this.city.value = addressParentModule.getMainCity;
      this.street.value = addressParentModule.getMainStreet;
      this.nr.value = addressParentModule.getMainNr;
    }
    this.emitInterface();
  }

  emitInterface() {
    this.$emit("interface", {
      isFormValid: () => this.isFormValid()
    });
  }

  isFormValid() {
    this.validatePlz();
    this.validateCity();
    this.validateStreet();
    this.validateNr();
    if (this.plz.isValid && this.city.isValid && this.street && this.nr) {
      this.$emit('valid-form', true, this.plz.value, this.city.value, this.street.value, this.nr.value)
    } else {
      this.$emit('valid-form', false, this.plz.value, this.city.value, this.street.value, this.nr.value)
    }
  }

  validatePlz() {
    this.plz.isValid = this.plz.value.trim() !== '';
  }

  validateCity() {
    this.city.isValid = this.city.value.trim() !== '';
  }

  validateStreet() {
    this.street.isValid = this.street.value.trim() !== '';
  }

  validateNr() {
    this.nr.isValid = this.nr.value.trim() !== '';
  }

  updateModel(action: string, item: string) {
    let result = []
    if (action === 'plz') {
      result = this.autocompleteData.filter((x: { postalCode: string; }) => x.postalCode === item);
      if (result[0].city !== undefined) {
        this.city.value = result[0].city;
        this.validateCity();
      }
    }
    if (action === 'city') {
      result = this.autocompleteData.filter((x: { city: string; }) => x.city === item)
      this.plz.value = result[0].postalCode;
      this.validatePlz();
    }
  }

  autocompleteCity() {
    this.autocompleteData = [];
    this.autocompleteCityData = [];
    let param = '';
    if (this.city.value !== '' && this.city.value.length > 3) {
      param = this.city.value;
    }
    if (param.length > 0) {
      this.axios.get('https://geocode.search.hereapi.com/v1/autocomplete', {
        params: {
          q: param,
          in: this.code,
          types: ('city'),
          lang: ('DEU'),
          apiKey: this.apiKey
        }
      }).then((response) => {
        for (let i = 0; i < response.data.items.length; i++) {
          this.autocompleteCityData.push(response.data.items[i]?.address.city);
          this.autocompleteData.push(response.data.items[i].address);
          if (this.plz.value === '' || (response.data.items?.length === 1 && this.plz.value.length
              < 5)) {
            this.plz.value = response.data.items[i]?.address.postalCode;
            this.validatePlz();
          }
        }
      })
    }
  }

  autocompletePlz() {
    this.autocompleteData = [];
    this.autocompletePlzData = [];
    let param = '';
    if (this.plz.value !== '' && this.plz.value.length > 2) {
      param = this.plz.value;
    }
    if (param.length > 0) {
      this.axios.get('https://geocode.search.hereapi.com/v1/autocomplete', {
        params: {
          q: param,
          in: this.code,
          types: ('postalCode'),
          lang: ('DEU'),
          apiKey: this.apiKey
        }
      }).then((response) => {
        for (let i = 0; i < response.data.items.length; i++) {
          this.autocompletePlzData.push(response.data.items[i]?.address.postalCode);
          this.autocompleteData.push(response.data.items[i].address);
          if (this.city.value === '' || response.data.items?.length === 1) {
            if (response.data.items[i]?.address.city !== undefined) {
              this.city.value = response.data.items[i]?.address.city;
              this.validateCity();
            }
          }
        }
      })
    }
  }

  autocompleteStreet() {
    this.autocompleteStreetData = [];
    let param = [];
    if (this.street.value !== '' && this.street.value.length > 3) {
      param.push(this.street.value);
    }
    if (param.length > 0) {
      if (this.plz.value !== '' && this.plz.value.length > 3) {
        param.unshift(this.plz.value);
      }
      if (this.city.value !== '' && this.city.value.length > 3) {
        param.unshift(this.city.value);
      }
      this.axios.get('https://geocode.search.hereapi.com/v1/autocomplete', {
        params: {
          q: param.toString(),
          in: this.code,
          lang: ('DEU'),
          apiKey: this.apiKey
        }
      }).then((response) => {
        for (let i = 0; i < response.data.items.length; i++) {
          if (!this.autocompleteStreetData.includes(response.data.items[i]?.address.street)) {
            this.autocompleteStreetData.push(response.data.items[i]?.address.street);
          }
        }
      })
    }
  }

  autocompleteNr() {
    this.autocompleteNrData = [];
    let param = [];
    if (this.plz.value !== '' && this.plz.value.length > 3) {
      param.push(this.plz.value);
    }
    if (this.city.value !== '' && this.city.value.length > 3) {
      param.push(this.city.value);
    }
    if (this.street.value !== '' && this.street.value.length > 3) {
      param.push(' ' + this.street.value);
    }
    if (this.nr.value !== '') {
      param.push('' + this.nr.value);
    }
    if (param.length > 0) {
      this.axios.get('https://geocode.search.hereapi.com/v1/autocomplete', {
        params: {
          q: param.toString(),
          in: this.code,
          lang: ('DEU'),
          apiKey: this.apiKey
        }
      }).then((response) => {
        for (let i = 0; i < response.data.items.length; i++) {
          this.autocompleteStreetData.push(response.data.items[i]?.address.street);
        }
      })
    }
  }
}
