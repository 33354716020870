
import {Options, Vue} from "vue-class-component";
import {Prop, Watch} from 'vue-property-decorator';
import BaseButton from "@/components/base/base-button.vue";

@Options({
  components: {BaseButton},
  emits: ['change-route']
})
export default class RegisterStepperHeader extends Vue {

  activeButton = 'user';
  finishedButton1 = {finished: false};
  finishedButton2 = {finished: false, disabled: false};
  finishedButton3 = {finished: false, disabled: false};
  finishedButton4 = {finished: false, disabled: false};


  @Prop() currentRoute!: string

  @Watch('currentRoute')
  changeRoute(newRoute: string) {
    this.activeButton = newRoute;
    if (newRoute === 'company') {
      this.finishedButton1.finished = true;
      this.finishedButton2.disabled = true;
    }
    if (newRoute === 'contact') {
      this.finishedButton2.finished = true;
      this.finishedButton3.disabled = true;
    }
    if (newRoute === 'result') {
      this.finishedButton3.finished = true;
      this.finishedButton4.disabled = true;
    }
  }

  activateButton(event: string) {
    this.activeButton = event;
    this.$emit('change-route', this.activeButton)
  }

  changeStep(step: string) {
    switch (step) {
      case 'company':
        if (this.finishedButton2.disabled) {
          this.activateButton(step);
        }
        break;
      case 'contact':
        if (this.finishedButton3.disabled) {
          this.activateButton(step);
        }
        break;
      case 'result':
        if (this.finishedButton4.disabled) {
          this.activateButton(step);
        }
    }
  }
}
