
import {Options, Vue} from "vue-class-component";
import {userModule} from '@/store/register/user';
import BaseInput from "@/components/base/base-input.vue";
import BaseButton from "@/components/base/base-button.vue";
import BaseLabel from "@/components/base/base-label.vue";
import {userStorageModule} from '@/store/user-storage';


@Options({
  components: {
    BaseInput,
    BaseButton,
    BaseLabel
  },
  emits: ['change-route'],
})
export default class RegisterStepperUser extends Vue {

  input = {value: '', isValid: true};
  usernameTaken = {value: false, active: false};

  validateUsername() {
    this.input.isValid = this.input.value.trim() !== '';
  }

  setUsername() {
    this.validateUsername();
    if (this.input.isValid) {
      userModule.actionSetUsername(this.input.value);
      userModule.actionSetUserForm(true);
    }
    this.checkUsername();
    if (userModule.getUserForm && !this.usernameTaken.value) {
      this.$emit('change-route', 'company');
    }
  }

  checkUsername() {
    this.validateUsername();
    if (this.input.isValid) {
      userModule.setUsername(this.input.value);
      this.usernameTaken.value = userStorageModule.checkUsername;
      this.usernameTaken.active = true;
    }
  }

  mounted() {
    userModule.setUserForm(false);
    this.input.value = userModule.getUsername;
  }
}
