import {VuexModule, Module} from "vuex-class-modules";
import store from '@/store';

@Module
export class CountriesModule extends VuexModule {

  countries = [
    {value: 'Afghanistan', code: 'AF'},
    {value: 'Ägypten', code: 'EG'},
    {value: 'Åland', code: 'AX'},
    {value: 'Albanien', code: 'AL'},
    {value: 'Algerien', code: 'DZ'},
    {value: 'Amerikanisch-Samoa', code: 'AS'},
    {value: 'Amerikanische Jungferninseln', code: 'VI'},
    {value: 'Andorra', code: 'AD'},
    {value: 'Angola', code: 'AO'},
    {value: 'Anguilla', code: 'AI'},
    {value: 'Antarktis', code: 'AQ'},
    {value: 'Antigua und Barbuda', code: 'AG'},
    {value: 'Äquatorialguinea', code: 'GQ'},
    {value: 'Argentinien', code: 'AR'},
    {value: 'Armenien', code: 'AM'},
    {value: 'Aruba', code: 'AW'},
    {value: 'Ascension', code: 'AC'},
    {value: 'Aserbaidschan', code: 'AZ'},
    {value: 'Äthiopien', code: 'ET'},
    {value: 'Australien', code: 'AU'},
    {value: 'Bahamas', code: 'BS'},
    {value: 'Bahrain', code: 'BH'},
    {value: 'Bangladesch', code: 'BD'},
    {value: 'Barbados', code: 'BB'},
    {value: 'Belarus', code: 'BY'},
    {value: 'Belgien', code: 'BE'},
    {value: 'Belize', code: 'BZ'},
    {value: 'Benin', code: 'BJ'},
    {value: 'Bermuda', code: 'BM'},
    {value: 'Bhutan', code: 'BT'},
    {value: 'Bolivien', code: 'BO'},
    {value: 'Bosnien und Herzegowina', code: 'BA'},
    {value: 'Botswana', code: 'BW'},
    {value: 'Bouvetinsel', code: 'BV'},
    {value: 'Brasilien', code: 'BR'},
    {value: 'Britische Jungferninseln', code: 'VG'},
    {value: 'Britisches Territorium im Indischen Ozean', code: 'IO'},
    {value: 'Brunei', code: 'BN'},
    {value: 'Bulgarien', code: 'BG'},
    {value: 'Burkina Faso', code: 'BF'},
    {value: 'Burundi', code: 'BI'},
    {value: 'Ceuta´Melilla', code: 'EA'},
    {value: 'Chile', code: 'CL'},
    {value: 'China', code: 'CN'},
    {value: 'Clipperton', code: 'CP'},
    {value: 'Cookinseln', code: 'CK'},
    {value: 'Costa Rica', code: 'CR'},
    {value: 'Curaçao', code: 'CW'},
    {value: 'Dänemark', code: 'DK'},
    {value: 'Deutschland', code: 'DE'},
    {value: 'Diego Garcia', code: 'DG'},
    {value: 'Dominica', code: 'DM'},
    {value: 'Dominikanische Republik', code: 'DO'},
    {value: 'Dschibuti', code: 'DJ'},
    {value: 'Ecuador', code: 'EC'},
    {value: 'Elfenbeinküste', code: 'CI'},
    {value: 'El Salvador', code: 'SV'},
    {value: 'Eritrea', code: 'ER'},
    {value: 'Estland', code: 'EE'},
    {value: 'Eswatini', code: 'SZ'},
    {value: 'Falklandinseln', code: 'FK'},
    {value: 'Färöer', code: 'FO'},
    {value: 'Fidschi', code: 'FJ'},
    {value: 'Finnland', code: 'FI'},
    {value: 'Frankreich', code: 'FR'},
    {value: 'Frankreich (metropolitanes)', code: 'FX'},
    {value: 'Französisch-Guayana', code: 'GF'},
    {value: 'Französisch-Polynesien', code: 'PF'},
    {value: 'Französische Süd- und Antarktisgebiete', code: 'TF'},
    {value: 'Gabun', code: 'GA'},
    {value: 'Gambia', code: 'GM'},
    {value: 'Georgien', code: 'GE'},
    {value: 'Ghana', code: 'GH'},
    {value: 'Gibraltar', code: 'GI'},
    {value: 'Grenada', code: 'GD'},
    {value: 'Griechenland', code: 'GR'},
    {value: 'Grönland', code: 'GL'},
    {value: 'Guadeloupe', code: 'GP'},
    {value: 'Guam', code: 'GU'},
    {value: 'Guatemala', code: 'GT'},
    {value: 'Guernsey', code: 'GG'},
    {value: 'Guinea', code: 'GN'},
    {value: 'Guinea-Bissau', code: 'GW'},
    {value: 'Guyana', code: 'GY'},
    {value: 'Haiti', code: 'HT'},
    {value: 'Heard und McDonaldinseln', code: 'HM'},
    {value: 'Honduras', code: 'HN'},
    {value: 'Hongkong', code: 'HK'},
    {value: 'Indien', code: 'IN'},
    {value: 'Indonesien', code: 'ID'},
    {value: 'Isle of Man', code: 'IM'},
    {value: 'Irak', code: 'IQ'},
    {value: 'Iran', code: 'IR'},
    {value: 'Irland', code: 'IE'},
    {value: 'Island', code: 'IS'},
    {value: 'Israel', code: 'IL'},
    {value: 'Italien', code: 'IT'},
    {value: 'Jamaika', code: 'JM'},
    {value: 'Japan', code: 'JP'},
    {value: 'Jemen', code: 'YE'},
    {value: 'Jersey', code: 'JE'},
    {value: 'Jordanien', code: 'JO'},
    {value: 'Cayman Islands', code: 'KY'},
    {value: 'Kambodscha', code: 'KH'},
    {value: 'Kamerun', code: 'CM'},
    {value: 'Kanada', code: 'CA'},
    {value: 'Kanarische Inseln', code: 'IC'},
    {value: 'Kap Verde', code: 'CV'},
    {value: 'Kasachstan', code: 'KZ'},
    {value: 'Katar', code: 'QA'},
    {value: 'Kenia', code: 'KE'},
    {value: 'Kirgisistan', code: 'KG'},
    {value: 'Kiribati', code: 'KI'},
    {value: 'Kokosinseln', code: 'CC'},
    {value: 'Kolumbien', code: 'CO'},
    {value: 'Komoren', code: 'KM'},
    {value: 'Demokratische Republik Kongo', code: 'CD'},
    {value: 'Republik Kongo', code: 'CG'},
    {value: 'Nordkorea', code: 'KP'},
    {value: 'Südkorea', code: 'KR'},
    {value: 'Kosovo', code: 'XK'},
    {value: 'Kroatien', code: 'HR'},
    {value: 'Kuba', code: 'CU'},
    {value: 'Kuwait', code: 'KW'},
    {value: 'Laos', code: 'LA'},
    {value: 'Lesotho', code: 'LS'},
    {value: 'Lettland', code: 'LV'},
    {value: 'Libanon', code: 'LB'},
    {value: 'Liberia', code: 'LR'},
    {value: 'Libyen', code: 'LY'},
    {value: 'Liechtenstein', code: 'LI'},
    {value: 'Litauen', code: 'LT'},
    {value: 'Luxemburg', code: 'LU'},
    {value: 'Macau', code: 'MO'},
    {value: 'Madagaskar', code: 'MG'},
    {value: 'Malawi', code: 'MW'},
    {value: 'Malaysia', code: 'MY'},
    {value: 'Malediven', code: 'MV'},
    {value: 'Mali', code: 'ML'},
    {value: 'Malta', code: 'MT'},
    {value: 'Marokko', code: 'MA'},
    {value: 'Marshallinseln', code: 'MH'},
    {value: 'Martinique', code: 'MQ'},
    {value: 'Mauretanien', code: 'MR'},
    {value: 'Mauritius', code: 'MU'},
    {value: 'Mayotte', code: 'YT'},
    {value: 'Mexiko', code: 'MX'},
    {value: 'Mikronesien', code: 'FM'},
    {value: 'Moldau', code: 'MD'},
    {value: 'Monaco', code: 'MC'},
    {value: 'Mongolei', code: 'MN'},
    {value: 'Montenegro', code: 'ME'},
    {value: 'Montserrat', code: 'MS'},
    {value: 'Mosambik', code: 'MZ'},
    {value: 'Myanmar', code: 'MM'},
    {value: 'Namibia', code: 'NA'},
    {value: 'Nauru', code: 'NR'},
    {value: 'Nepal', code: 'NP'},
    {value: 'Neukaledonien', code: 'NC'},
    {value: 'Neuseeland', code: 'NZ'},
    {value: 'Nicaragua', code: 'NI'},
    {value: 'Niederlande', code: 'NL'},
    {value: 'Niger', code: 'NE'},
    {value: 'Nigeria', code: 'NG'},
    {value: 'Niue', code: 'NU'},
    {value: 'Nördliche Marianen', code: 'MP'},
    {value: 'Nordmazedonien', code: 'MK'},
    {value: 'Norfolkinsel', code: 'NF'},
    {value: 'Norwegen', code: 'NO'},
    {value: 'Oman', code: 'OM'},
    {value: 'Österreich', code: 'AT'},
    {value: 'Osttimor', code: 'TL'},
    {value: 'Pakistan', code: 'PK'},
    {value: 'Palästina', code: 'PS'},
    {value: 'Palau', code: 'PW'},
    {value: 'Panama', code: 'PA'},
    {value: 'Papua-Neuguinea', code: 'PG'},
    {value: 'Paraguay', code: 'PY'},
    {value: 'Peru', code: 'PE'},
    {value: 'Philippinen', code: 'PH'},
    {value: 'Pitcairninseln', code: 'PN'},
    {value: 'Polen', code: 'PL'},
    {value: 'Portugal', code: 'PT'},
    {value: 'Puerto Rico', code: 'PR'},
    {value: 'Réunion', code: 'RE'},
    {value: 'Ruanda', code: 'RW'},
    {value: 'Rumänien', code: 'RO'},
    {value: 'Russland', code: 'RU'},
    {value: 'Salomonen', code: 'SB'},
    {value: 'Saint-Barthélemy', code: 'BL'},
    {value: 'Saint-Martin', code: 'MF'},
    {value: 'Sambia', code: 'ZM'},
    {value: 'Samoa', code: 'WS'},
    {value: 'San Marino', code: 'SM'},
    {value: 'São Tomé und Príncipe', code: 'ST'},
    {value: 'Saudi-Arabien', code: 'SA'},
    {value: 'Schweden', code: 'SE'},
    {value: 'Schweiz', code: 'CH'},
    {value: 'Senegal', code: 'SN'},
    {value: 'Serbien', code: 'RS'},
    {value: 'Seychellen', code: 'SC'},
    {value: 'Sierra Leone', code: 'SL'},
    {value: 'Simbabwe', code: 'ZW'},
    {value: 'Singapur', code: 'SG'},
    {value: 'Sint Maarten', code: 'SX'},
    {value: 'Slowakei', code: 'SK'},
    {value: 'Slowenien', code: 'SI'},
    {value: 'Somalia', code: 'SO'},
    {value: 'Spanien', code: 'ES'},
    {value: 'Sri Lanka', code: 'LK'},
    {value: 'St. Helena', code: 'SH'},
    {value: 'St. Kitts und Nevis', code: 'KN'},
    {value: 'St. Lucia', code: 'LC'},
    {value: 'Saint-Pierre und Miquelon', code: 'PM'},
    {value: 'St. Vincent und die Grenadinen', code: 'VC'},
    {value: 'Südafrika', code: 'ZA'},
    {value: 'Sudan', code: 'SD'},
    {value: 'Südgeorgien und die Südlichen Sandwichinseln', code: 'GS'},
    {value: 'Südsudan', code: 'SS'},
    {value: 'Suriname', code: 'SR'},
    {value: 'Spitzbergen und Jan Mayen', code: 'SJ'},
    {value: 'Syrien', code: 'SY'},
    {value: 'Tadschikistan', code: 'TJ'},
    {value: 'Taiwan', code: 'TW'},
    {value: 'Tansania', code: 'TZ'},
    {value: 'Thailand', code: 'TH'},
    {value: 'Togo', code: 'TG'},
    {value: 'Tokelau', code: 'TK'},
    {value: 'Tonga', code: 'TO'},
    {value: 'Trinidad und Tobago', code: 'TT'},
    {value: 'Tristan da Cunha', code: 'TA'},
    {value: 'Tschad', code: 'TD'},
    {value: 'Tschechien', code: 'CZ'},
    {value: 'Tunesien', code: 'TN'},
    {value: 'Türkei', code: 'TR'},
    {value: 'Turkmenistan', code: 'TM'},
    {value: 'Turks- und Caicosinseln', code: 'TC'},
    {value: 'Tuvalu', code: 'TV'},
    {value: 'Uganda', code: 'UG'},
    {value: 'Ukraine', code: 'UA'},
    {value: 'Ungarn', code: 'HU'},
    {value: 'United States Minor Outlying Islands', code: 'UM'},
    {value: 'Uruguay', code: 'UY'},
    {value: 'Usbekistan', code: 'UZ'},
    {value: 'Vanuatu', code: 'VU'},
    {value: 'Vatikanstadt', code: 'VA'},
    {value: 'Venezuela', code: 'VE'},
    {value: 'Vereinigte Arabische Emirate', code: 'AE'},
    {value: 'Vereinigte Staaten', code: 'US'},
    {value: 'Vereinigtes Königreich', code: 'GB'},
    {value: 'Vietnam', code: 'VN'},
    {value: 'Wallis und Futuna', code: 'WF'},
    {value: 'Weihnachtsinsel', code: 'CX'},
    {value: 'Westsahara', code: 'EH'},
    {value: 'Zentralafrikanische Republik', code: 'CF'},
    {value: 'Zypern', code: 'CY'}
  ];

  get getCountry() {
    return this.countries;
  }
}

export const countriesModule = new CountriesModule({store, name: "countries"});
