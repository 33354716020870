
import {Options, Vue} from "vue-class-component";
import BaseButton from '@/components/base/base-button.vue';
import BaseInput from '@/components/base/base-input.vue';
import BaseAutocomplete from '@/components/base/base-autocomplete.vue';
import BaseLabel from '@/components/base/base-label.vue';
import {Prop} from 'vue-property-decorator';
import {requestCompanyModule} from '@/store/request/request-company';
import {requestParentModule} from '@/store/request/request-parent';

@Options({
  components: {
    BaseButton,
    BaseInput,
    BaseAutocomplete,
    BaseLabel
  },
  emits: ['valid-form', 'interface']
})
export default class RequestBaseCompany extends Vue {

  @Prop() type!: string;

  // eslint-disable-next-line
  autocompleteData: any[] = [];
  // eslint-disable-next-line
  autocompleteCompanyData: any[] = [];

  company = {value: '', isValid: true};
  regId = {value: '', isValid: true};

  mounted() {
    if (this.type === 'stepper-company') {
      this.company.value = requestCompanyModule.getCompany;
      this.regId.value = requestCompanyModule.getRegId;
    }
    if (this.type === 'stepper-parent-company') {
      this.company.value = requestParentModule.getParentCompany;
      this.regId.value = requestParentModule.getParentRegId;
    }
    if (this.type === 'stepper-main-company') {
      this.company.value = requestParentModule.getMainCompany;
      this.regId.value = requestParentModule.getMainRegId;
    }
    this.emitInterface();
  }

  emitInterface() {
    this.$emit("interface", {
      isFormValid: () => this.isFormValid()
    });
  }

  isFormValid() {
    this.validateCompany();
    this.validateRegId();
    if (this.company.isValid && this.regId.isValid) {
      this.$emit('valid-form', true, this.company.value, this.regId.value)
    } else {
      this.$emit('valid-form', false, this.company.value, this.regId.value)
    }
  }


  updateModel(action: string, item: string) {
    let result = []
    if (action === 'company') {
      result = this.autocompleteData.filter((x) => x.data.NAME[0] === item);
      this.regId.value = result[0].value;
      this.validateRegId();
    }
  }

  validateCompany() {
    this.company.isValid = this.company.value.trim() !== '';
  }

  validateRegId() {
    this.regId.isValid = this.regId.value.trim() !== '' && /HR(A|B)\s?[0-9]{4,6}/.test(this.regId.value);
  }

  autocompleteCompany() {
    this.autocompleteData = [];
    this.autocompleteCompanyData = [];

    if (this.company.isValid && this.company.value.length > 2) {
      this.axios.get(`http://localhost:8080/api/${this.company.value}`, {
        params: {
          count: 5
        }
      })
      .then((response) => {
        this.autocompleteData = [];
        this.autocompleteCompanyData = [];
        for (let i = 0; i < response.data.results.length; i++) {
          if (!this.autocompleteCompanyData.find(x => x === response.data.results[i].data.NAME[0])) {
            this.autocompleteCompanyData.push(response.data.results[i].data.NAME[0]);
            this.autocompleteData.push(response.data.results[i]);
          }
        }
      })
    }
  }
}
