
import {Options, Vue} from "vue-class-component";
import SearchOutlineHeader from '@/components/search/outline/search-outline-header.vue';
import SearchOutlineFooter from '@/components/search/outline/search-outline-footer.vue';
import SearchBar from '@/components/search/search-bar.vue';

@Options({
  components: {
    SearchBar,
    SearchOutlineFooter,
    SearchOutlineHeader,
  }
})
export default class LeiSearch extends Vue {
}
