
import {Options, Vue} from "vue-class-component";
import BaseButton from "@/components/base/base-button.vue";
import {requestResultModule} from '@/store/request/request-result';
import {requestCompanyModule} from '@/store/request/request-company';
import {addressModule} from '@/store/request/address';
import {requestParentModule} from '@/store/request/request-parent';
import {addressParentModule} from '@/store/request/address-parent';
import {cartModule} from '@/store/cart/cart';
import {Lei} from "@/store/model";

@Options({
  components: {
    BaseButton
  },
  emits: ['change-route'],
})

export default class RequestStepperResult extends Vue {

  company: string[] = [];
  counter = 0;

  backToParent() {
    this.$emit('change-route', 'parent');
  }

  addToCart() {
    if (requestCompanyModule.getCompanyForm && addressModule.getAddressForm && requestParentModule.getParentForm) {

      let plz = addressModule.getPlz;
      let city = addressModule.getCity;
      let street = addressModule.getStreet;
      let nr = addressModule.getNr;

      let juPlz = plz;
      let juCity = city;
      let juStreet = street;
      let juNr = nr;

      if (!addressModule.getValue) {
        juPlz = addressModule.getJuPlz;
        juCity = addressModule.getJuCity;
        juStreet = addressModule.getJuStreet;
        juNr = addressModule.getJuNr;
      }

      requestResultModule.actionSetCart({
        company: requestCompanyModule.getCompany,
        regId: requestCompanyModule.getRegId,
        taxId: requestCompanyModule.getTaxId,
        plz: plz,
        city: city,
        street: street,
        nr: nr,
        juPlz: juPlz,
        juCity: juCity,
        juStreet: juStreet,
        juNr: juNr,
        parentCompany: requestParentModule.getParentCompany,
        parentRegId: requestParentModule.getParentRegId,
        parentPlz: requestParentModule.getParentPlz,
        parentCity: requestParentModule.getParentCity,
        parentStreet: requestParentModule.getParentStreet,
        parentNr: requestParentModule.getParentNr,
        mainCompany: requestParentModule.getMainCompany,
        mainRegId: requestParentModule.getMainRegId,
        mainPlz: requestParentModule.getMainPlz,
        mainCity: requestParentModule.getMainCity,
        mainStreet: requestParentModule.getMainStreet,
        mainNr: requestParentModule.getMainNr,
      })
      requestCompanyModule.actionReset();
      addressModule.actionReset();
      addressParentModule.actionReset();
      requestParentModule.actionReset();
    }
  }

  anotherOne() {
    this.addToCart();
    this.$emit('change-route', 'start');

  }

  submitResult() {
    this.addToCart();
    let cart = requestResultModule.getCart;
    for (let i = 0; i < requestResultModule.getCartCounter; i++) {

      let id= []

      for(let j= 0; j<20; j++){
        id.push(Math.floor(Math.random()*10));
      }

      let lei: Lei = {
        legalEntity: {
          legalName: cart[i].company,
          legalForm: 'Europäische Aktiengesellschaft SGST',
          jurisdiction: 'Germany (DE)',
          businessRegistryIdentifier: cart[i].regId,
          status: 'ACTIVE'
        },
        legalAddress: {
          street: cart[i].street +' ' + cart[i].nr,
          postalCode: cart[i].plz,
          city: cart[i].city,
          region: 'DE',
          country: 'Deutschland'
        },
        headquartersAddress: {
          street: cart[i].juStreet+ ' '+ cart[i].juNr,
          postalCode: cart[i].juPlz,
          city: cart[i].juCity,
          region: 'DE',
          country: 'Deutschland'
        },
        registrationDetails: {
          lei: id.join(""),
          status: 'ISSUED',
          initialStatus: new Date(),
          nextRenewal: new Date('2023-05-10'),
          lastUpdate: new Date(),
          managing: cart[i].parentCompany,
          validationSources: cart[i].mainCompany
        }
      }
      cartModule.actionAddCartItem(lei);
      cartModule.actionAddType('request');
    }
    this.$router.replace('/cart');
  }

  mounted() {
    this.company = requestResultModule.getCompany;
    this.counter = requestResultModule.getCounter;
  }

}
