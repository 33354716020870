
import {Options, Vue} from "vue-class-component";
import BaseToggle from '@/components/base/base-toggle.vue';
import BaseButton from '@/components/base/base-button.vue';
import RequestBaseCompany from '@/components/request/base/request-base-company.vue';
import RequestBaseAddress from '@/components/request/base/request-base-address.vue';
import {requestParentModule} from '@/store/request/request-parent';
import {addressParentModule} from '@/store/request/address-parent';

@Options({
  components: {
    RequestBaseAddress,
    RequestBaseCompany,
    BaseToggle,
    BaseButton,
  },
  emits: ['change-route'],
})
export default class RequestStepperParent extends Vue {

  value1 = false;
  value2 = false;
  value3 = true;

  apiKey = 'cn9bewzqlYNTFyQhy7Hky3O64MzA5xinLuACfHzccEU';

  parentCompany = '';
  parentRegId = '';
  formParentCompanyValid = true;

  mainCompany = '';
  mainRegId = '';
  formMainCompanyValid = true;

  parentPlz = '';
  parentCity = '';
  parentStreet = '';
  parentNr = '';
  formParentAddressValid = true
  addressParentValid = true;

  mainPlz = '';
  mainCity = '';
  mainStreet = '';
  mainNr = '';
  formMainAddressValid = true;
  addressMainValid = true;

  changeValue1(){
    this.value1=!this.value1;
    if(this.value2){
      this.value2=false;
    }
    if(!this.value3){
      this.value3=true;
    }
  }

  changeValue2(){
    this.value2=!this.value2;
    if(!this.value3){
      this.value3=true
    }
  }

  // eslint-disable-next-line
  getParentCompanyInterface(companyInterface: any) {
    this.$options.companyParentInterface = companyInterface;
  }

  // eslint-disable-next-line
  getMainCompanyInterface(companyInterface: any) {
    this.$options.companyMainInterface = companyInterface;
  }

  // eslint-disable-next-line
  getAddressParentInterface(companyInterface: any) {
    this.$options.addressParentInterface = companyInterface;
  }

  // eslint-disable-next-line
  getAddressMainInterface(companyInterface: any) {
    this.$options.addressMainInterface = companyInterface;
  }

  validParentCompany(valid: boolean, company: string, regId: string) {
    this.formParentCompanyValid = valid;
    this.parentCompany = company;
    this.parentRegId = regId;
  }

  validMainCompany(valid: boolean, company: string, regId: string) {
    this.formMainCompanyValid = valid;
    this.mainCompany = company;
    this.mainRegId = regId;
  }

  validParentAddress(valid: boolean, plz: string, city: string, street: string, nr: string) {
    this.formParentAddressValid = valid;
    this.parentPlz = plz;
    this.parentCity = city;
    this.parentStreet = street;
    this.parentNr = nr;
  }

  validMainAddress(valid: boolean, plz: string, city: string, street: string, nr: string) {
    this.formMainAddressValid = valid;
    this.mainPlz = plz;
    this.mainCity = city;
    this.mainStreet = street;
    this.mainNr = nr;
  }

  submitParent() {

    requestParentModule.actionSetValue1(this.value1);
    requestParentModule.actionSetValue2(this.value2);
    requestParentModule.actionSetValue3(this.value3);

    if (!this.value1 || this.value1 && !this.value2) {
      this.formParentCompanyValid = true;
      this.formParentAddressValid = true;
      this.formMainCompanyValid = true;
      this.formMainAddressValid = true;
      requestParentModule.actionSetParentForm(true);
      this.$emit('change-route', 'result');
    } else if (this.value1 && this.value2 && this.value3) {
      this.$options.companyParentInterface.isFormValid();
      this.$options.addressParentInterface.isFormValid();
      this.formMainCompanyValid = true;
      this.formMainAddressValid = true;
    } else if (this.value1 && this.value2 && !this.value3) {
      this.$options.companyParentInterface.isFormValid();
      this.$options.addressParentInterface.isFormValid();
      this.$options.companyMainInterface.isFormValid();
      this.$options.addressMainInterface.isFormValid();
    }

    if (this.formParentCompanyValid && this.formParentAddressValid) {
      requestParentModule.actionSetParentCompany(this.parentCompany);
      requestParentModule.actionSetParentRegId(this.parentRegId);

      const param = [];
      param.push('Deutschland')
      param.push(this.parentPlz);
      param.push(this.parentCity);
      param.push(this.parentStreet);
      param.push(this.parentNr);

      this.axios.get('https://geocode.search.hereapi.com/v1/autocomplete', {
        params: {
          q: param.toString(),
          apiKey: this.apiKey
        }
      }).then((response) => {
        if (response.data.items.length > 0) {
          const result = response.data.items[0].title.split(' ');
          this.addressParentValid = result.length > 4;
          if (this.addressParentValid) {
            addressParentModule.actionSetParentPlz(this.parentPlz);
            addressParentModule.actionSetParentCity(this.parentCity);
            addressParentModule.actionSetParentStreet(this.parentStreet);
            addressParentModule.actionSetParentNr(this.parentNr);
            if (!this.value3) {
              if (this.formMainCompanyValid && this.formMainAddressValid) {
                requestParentModule.actionSetMainCompany(this.mainCompany);
                requestParentModule.actionSetMainRegId(this.mainRegId);
                this.checkMainAddress();
              }
            } else {
              requestParentModule.actionSetParentForm(true);
              this.$emit('change-route', 'result');
            }
          }
        } else {
          this.addressParentValid = false;
        }
      })
    }
  }

  checkMainAddress() {
    const param = [];
    param.push('Deutschland')
    param.push(this.mainPlz);
    param.push(this.mainCity);
    param.push(this.mainStreet);
    param.push(this.mainNr);

    this.axios.get('https://geocode.search.hereapi.com/v1/autocomplete', {
      params: {
        q: param.toString(),
        apiKey: this.apiKey
      }
    }).then((response) => {
      if (response.data.items.length > 0) {
        const result = response.data.items[0].title.split(' ');
        this.addressMainValid = result.length > 4;
        if (this.addressMainValid) {
          addressParentModule.actionSetMainPlz(this.mainPlz);
          addressParentModule.actionSetMainCity(this.mainCity);
          addressParentModule.actionSetMainStreet(this.mainStreet);
          addressParentModule.actionSetMainNr(this.mainNr);
          requestParentModule.actionSetParentForm(true);
          this.$emit('change-route', 'result');
        }
      } else {
        this.addressMainValid = false;
      }
    })
  }

  backToAddress() {
    this.$emit('change-route', 'address')
  }

  mounted() {
    this.value1 = requestParentModule.getValue1;
    this.value2 = requestParentModule.getValue2;
    this.value3 = requestParentModule.getValue3;
  }

}
