
import {Vue} from 'vue-class-component';
import {Prop} from 'vue-property-decorator';

export default class BaseInput extends Vue {

  @Prop() modelValue!: string
  @Prop() onBlur!: string
  @Prop() onKeyUp!: string
  @Prop() valid!: boolean
  @Prop() width!: string
  @Prop() type!: string
  @Prop({ default: false }) disabled!: boolean

  updateInput(event: string) {
    this.$emit("update:modelValue", event);
  }
}
