
import {Vue} from 'vue-class-component';
import {userStorageModule} from '@/store/user-storage';

export default class UserCurrent extends Vue {

  user: {
    id: string,
    username: string,
    company: string,
    co: string,
    land: string,
    plz: string,
    city: string,
    street: string,
    nr: string,
    salutation: string,
    title: string,
    firstName: string,
    lastName: string,
    position: string,
    phone: string,
    email: string,
    password: string
  } = {
    id: '',
    username: '',
    company: '',
    co: '',
    land: '',
    plz: '',
    city: '',
    street: '',
    nr: '',
    salutation: '',
    title: '',
    firstName: '',
    lastName: '',
    position: '',
    phone: '',
    email: '',
    password: ''
  };

  created() {
    let user = userStorageModule.getUsers.find(user => user.id === this.$route.params.id.toString());
    if (user !== undefined) {
      this.user = user;
    }
  }

}
