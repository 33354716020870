
import {Options, Vue} from "vue-class-component";
import BaseInput from "@/components/base/base-input.vue";
import BaseButton from "@/components/base/base-button.vue";
import BaseLabel from "@/components/base/base-label.vue";
import SelectorSalutation from "@/components/base/selector/selector-salutation.vue";
import SelectorTitle from "@/components/base/selector/selector-title.vue";
import {contactModule} from '@/store/register/contact';

@Options({
  components: {
    SelectorTitle,
    SelectorSalutation,
    BaseLabel,
    BaseButton,
    BaseInput
  },
  emits: ['change-route'],
})

export default class RegisterStepperContact extends Vue {


  salutation = {value: '', isValid: true};
  title = {value: '', isValid: true};
  firstName = {value: '', isValid: true};
  lastName = {value: '', isValid: true};
  position = {value: '', isValid: true};
  phone = {value: '', isValid: true};
  email = {value: '', isValid: true};
  emailConfirm = {value: '', isValid: true};


  backToCompany() {
    this.$emit('change-route', 'company')
  }

  validateSalutation() {
    this.salutation.isValid = this.salutation.value.trim() !== '';
  }

  validateTitle() {
    this.title.isValid = true;
  }

  validateFirstName() {
    this.firstName.isValid = this.firstName.value.trim() !== '';
  }

  validateLastName() {
    this.lastName.isValid = this.lastName.value.trim() !== '';
  }

  validatePosition() {
    this.position.isValid = this.position.value.trim() !== '';
  }

  validatePhone() {
    this.phone.isValid = this.phone.value.trim() !== '' && this.phone.value.trim().length >= 6 && /^(\+|0)([0-9]| )*$/.test(this.phone.value);
  }

  validateEmail() {
    this.email.isValid = this.email.value.trim() !== '' &&  /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(this.email.value);
  }

  validateEmailConfirm() {
    this.emailConfirm.isValid = (this.emailConfirm.value.trim() !== '' && this.email.value
        === this.emailConfirm.value && this.email.value.includes('@'));
  }

  submitContact() {
    this.validateSalutation();
    this.validateTitle();
    this.validateFirstName();
    this.validateLastName();
    this.validatePosition();
    this.validatePhone();
    this.validateEmail();
    this.validateEmailConfirm();

    if (this.salutation.isValid && this.title.isValid && this.firstName.isValid
        && this.lastName.isValid && this.position.isValid && this.phone.isValid
        && this.email.isValid && this.emailConfirm.isValid) {

      contactModule.actionSetSalutation(this.salutation.value);
      contactModule.actionSetTitle(this.title.value);
      contactModule.actionSetFirstName(this.firstName.value);
      contactModule.actionSetLastName(this.lastName.value);
      contactModule.actionSetPosition(this.position.value);
      contactModule.actionSetPhone(this.phone.value);
      contactModule.actionSetEmail(this.email.value);
      contactModule.actionSetEmailConfirm(this.emailConfirm.value);
      contactModule.actionSetContactForm(true);
      this.$emit('change-route', 'result');
    }
  }

  mounted() {
    contactModule.setContactForm(false);
    this.salutation.value = contactModule.getSalutation;
    this.title.value = contactModule.getTitle;
    this.firstName.value = contactModule.getFirstName;
    this.lastName.value = contactModule.getLastName;
    this.position.value = contactModule.getPosition;
    this.phone.value = contactModule.getPhone;
    this.email.value = contactModule.getEmail;
    this.emailConfirm.value = contactModule.getEmailConfirm;
  }
}
