
import { Options, Vue } from "vue-class-component";
import { searchModule } from "@/store/search/search";
import SearchResult from "@/components/search/search-result.vue";
import { countriesModule } from "@/store/register/countries";
import router from "@/router";
import BaseInput from "@/components/base/base-input.vue";
import BaseButton from "@/components/base/base-button.vue";

@Options({
  components: {
    BaseButton,
    BaseInput,
    SearchResult,
  },
})
export default class SearchBar extends Vue {
  input = { value: "", isValid: true };
  results: { name: string; lei: string }[] = [];
  isOpen = false;
  searchActive = false;
  option = "Alles";
  dataSet = "2,114,526";

  inputValidity() {
    this.input.isValid = this.input.value.trim() !== "";
  }

  updateSearch(result: { name: string; lei: string }) {
    console.log(result.lei);
    if (result.lei !== "") {
      router.push({ path: "/search/" + result.lei });
    } else {
      this.input.value = result.name;
      this.isOpen = false;
      this.searchResult();
    }
  }

  searchLei() {
    this.results = [];
    this.inputValidity();
    this.isOpen = true;
    if (this.input.isValid) {
      this.axios
        .get("https://api.gleif.org/api/v1/autocompletions", {
          params: {
            field: "fulltext",
            q: this.input.value,
          },
        })
        .then((response) => {
          for (let i = 0; i < response.data.data.length; i++) {
            let tempLei = "";
            if (response.data.data[i].relationships !== undefined) {
              tempLei =
                response.data.data[i].relationships["lei-records"].data.id;
            }
            this.results.push({
              name: response.data.data[i].attributes.value,
              lei: tempLei,
            });
          }
        });
    }
  }

  searchResult() {
    this.inputValidity();
    if (this.input.isValid) {
      this.isOpen = false;
      searchModule.clearItems();
      this.searchActive = searchModule.getResult;
      this.axios
        .get("https://api.gleif.org/api/v1/lei-records", {
          params: {
            "filter[fulltext]": this.input.value,
            "page[number]": 1,
            "page[size]": 200,
          },
        })
        .then((response) => {
          for (let i = 0; i < response.data.data.length; i++) {
            let itemLei = response.data.data[i].id;
            let itemName =
              response.data.data[i].attributes.entity.legalName.name;
            let itemJs: { street: string; city: string; country: string } = {
              street: "",
              city: "",
              country: "",
            };
            let itemJsStreet = [];
            for (
              let j = 0;
              j <
              response.data.data[i].attributes.entity.legalAddress.addressLines
                .length;
              j++
            ) {
              itemJsStreet.push(
                response.data.data[i].attributes.entity.legalAddress
                  .addressLines[j]
              );
            }
            itemJs.street = itemJsStreet.toString();
            itemJs.city =
              response.data.data[i].attributes.entity.legalAddress.postalCode +
              " " +
              response.data.data[i].attributes.entity.legalAddress.city;
            let countryJS = countriesModule.getCountry.filter(
              (x) =>
                x.code ===
                response.data.data[i].attributes.entity.legalAddress.country
            );
            itemJs.country = countryJS[0].value;

            let itemVs: { street: string; city: string; country: string } = {
              street: "",
              city: "",
              country: "",
            };
            let itemVsStreet = [];
            for (
              let j = 0;
              j <
              response.data.data[i].attributes.entity.headquartersAddress
                .addressLines.length;
              j++
            ) {
              itemVsStreet.push(
                response.data.data[i].attributes.entity.headquartersAddress
                  .addressLines[j]
              );
            }
            itemVs.street = itemVsStreet.toString();
            itemVs.city =
              response.data.data[i].attributes.entity.headquartersAddress
                .postalCode +
              " " +
              response.data.data[i].attributes.entity.headquartersAddress.city;
            let countryVS = countriesModule.getCountry.filter(
              (x) =>
                x.code ===
                response.data.data[i].attributes.entity.headquartersAddress
                  .country
            );
            itemVs.country = countryVS[0].value;

            let itemStatus =
              response.data.data[i].attributes.registration.status;

            searchModule.addItem({
              lei: itemLei,
              name: itemName,
              js: itemJs,
              vs: itemVs,
              status: itemStatus,
            });
          }
          if (response.data.data.length < 1) {
            searchModule.setResult(true);
          }
          searchModule.setAmount(response.data.meta.pagination.total);
          this.searchActive = searchModule.getResult;
        });
    }
  }

  handleClickOutside(event: Event) {
    if (!this.$el.contains(event.target)) {
      this.isOpen = false;
    }
  }

  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  }

  unmounted() {
    document.removeEventListener("click", this.handleClickOutside);
  }
}
