import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "autocomplete" }
const _hoisted_2 = ["value", "maxlength"]
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("input", {
      type: "text",
      onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.updateInput($event.target.value))),
      value: _ctx.modelValue,
      onBlur: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onBlur && _ctx.onBlur(...args))),
      onKeyup: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onKeyUp && _ctx.onKeyUp(...args))),
      class: _normalizeClass({invalid: !_ctx.valid}),
      style: _normalizeStyle({width: _ctx.width}),
      maxlength: _ctx.maxLength
    }, null, 46, _hoisted_2),
    (_ctx.isOpen)
      ? _withDirectives((_openBlock(), _createElementBlock("ul", {
          key: 0,
          id: "autocomplete-results",
          class: "autocomplete-results",
          style: _normalizeStyle({width: _ctx.width})
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
            return (_openBlock(), _createElementBlock("li", {
              key: item,
              class: "autocomplete-result",
              onClick: ($event: any) => (_ctx.updateModel(item))
            }, _toDisplayString(item), 9, _hoisted_3))
          }), 128))
        ], 4)), [
          [_vShow, _ctx.dropdown]
        ])
      : _createCommentVNode("", true)
  ]))
}