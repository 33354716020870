import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_lei_details = _resolveComponent("lei-details")!
  const _component_search_outline_footer = _resolveComponent("search-outline-footer")!

  return (_ctx.resultReady)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_lei_details, {
          "back-uri": "search",
          lei: _ctx.lei
        }, null, 8, ["lei"]),
        _createVNode(_component_search_outline_footer)
      ]))
    : _createCommentVNode("", true)
}