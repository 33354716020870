import {VuexModule, Module, Mutation, Action} from "vuex-class-modules";
import store from '@/store';

@Module
export class RequestCompanyModule extends VuexModule {

  company = '';
  regId = '';
  taxId = '';
  companyFormValid = false;

  @Mutation
  reset() {
    this.company='';
    this.regId='';
    this.taxId='';
    this.companyFormValid=false;
  }

  @Mutation
  setCompany(company: string) {
    this.company = company;
  }

  @Mutation
  setTaxId(taxId: string) {
    this.taxId = taxId;
  }

  @Mutation
  setRegId(regId: string) {
    this.regId = regId;
  }

  @Mutation
  setCompanyForm(valid: boolean) {
    this.companyFormValid = valid;
  }

  @Action
  actionReset() {
    this.reset();
  }

  @Action
  actionSetCompany(company: string) {
    this.setCompany(company);
  }

  @Action
  actionSetRegId(regId: string) {
    this.setRegId(regId);
  }

  @Action
  actionSetTaxId(taxId: string) {
    this.setTaxId(taxId);
  }

  @Action
  actionSetCompanyForm(valid: boolean) {
    this.setCompanyForm(valid);
  }

  get getCompany() {
    return this.company;
  }

  get getTaxId() {
    return this.taxId;
  }

  get getRegId() {
    return this.regId;
  }

  get getCompanyForm() {
    return this.companyFormValid;
  }
}

export const requestCompanyModule = new RequestCompanyModule({store, name: "request-company"});



