
import {Options, Vue} from 'vue-class-component';
import LeiDetails from "@/components/base/lei-details.vue";
import { Lei } from "@/store/model";
import {countriesModule} from "@/store/register/countries";
import SearchOutlineFooter from '@/components/search/outline/search-outline-footer.vue';

@Options({
  components: {
    LeiDetails, SearchOutlineFooter
  }
})
export default class LeiSearchDetails extends Vue {

  lei!: Lei;
  resultReady = false;

  created(){
    this.lei = {
      legalEntity: {
        legalName: '',
        legalForm: '',
        jurisdiction: '',
        businessRegistryIdentifier: '',
        status: ''
      },
      legalAddress: {
        street: '',
        postalCode: '',
        city: '',
        region: '',
        country: ''
      },
      headquartersAddress: {
        street: '',
        postalCode: '',
        city: '',
        region: '',
        country: ''
      },
      registrationDetails: {
        lei: '',
        status: '',
        initialStatus: new Date(),
        nextRenewal: new Date(),
        lastUpdate: new Date(),
        managing: '',
        validationSources: ''
      },
    };

    this.axios.get('https://api.gleif.org/api/v1/lei-records/' + this.$route.params.id.toString()).then((response) => {
      this.lei.legalEntity.legalName = response.data.data.attributes.entity.legalName.name;
      this.lei.legalEntity.businessRegistryIdentifier = response.data.data.attributes.entity.registeredAs;
      this.lei.legalEntity.status = response.data.data.attributes.entity.status;

      let streetJs = []
      for (let i = 0; i < response.data.data.attributes.entity.legalAddress.addressLines.length; i++) {
        streetJs.push(response.data.data.attributes.entity.legalAddress.addressLines[i]);
      }
      this.lei.legalAddress.street = streetJs.toString()
      this.lei.legalAddress.postalCode = response.data.data.attributes.entity.legalAddress.postalCode;
      this.lei.legalAddress.city = response.data.data.attributes.entity.legalAddress.city;
      this.lei.legalAddress.region = response.data.data.attributes.entity.legalAddress.region;
      let countryJs = countriesModule.getCountry.filter(x => x.code === response.data.data.attributes.entity.legalAddress.country);
      this.lei.legalAddress.country = countryJs[0].value;

      let streetHs = []
      for (let i = 0; i < response.data.data.attributes.entity.headquartersAddress.addressLines.length; i++) {
        streetHs.push(response.data.data.attributes.entity.headquartersAddress.addressLines[i]);
      }
      this.lei.headquartersAddress.street = streetHs.toString()
      this.lei.headquartersAddress.postalCode = response.data.data.attributes.entity.headquartersAddress.postalCode;
      this.lei.headquartersAddress.city = response.data.data.attributes.entity.headquartersAddress.city;
      this.lei.headquartersAddress.region = response.data.data.attributes.entity.headquartersAddress.region;
      let countryHs = countriesModule.getCountry.filter(x => x.code === response.data.data.attributes.entity.headquartersAddress.country);
      this.lei.headquartersAddress.country = countryHs[0].value;

      this.lei.registrationDetails.lei = response.data.data.attributes.lei;
      this.lei.registrationDetails.status = response.data.data.attributes.registration.status;
      let initDate = new Date(response.data.data.attributes.registration.initialRegistrationDate);
      let nextDate = new Date(response.data.data.attributes.registration.nextRenewalDate);
      let lastDate = new Date(response.data.data.attributes.registration.lastUpdateDate);

      this.lei.registrationDetails.initialStatus = initDate;
      this.lei.registrationDetails.nextRenewal = nextDate;
      this.lei.registrationDetails.lastUpdate = lastDate;
      this.lei.registrationDetails.managing = response.data.data.attributes.registration.managingLou;
      this.lei.registrationDetails.validationSources = response.data.data.attributes.registration.corroborationLevel;

      return this.axios.get('https://api.gleif.org/api/v1/entity-legal-forms/' + response.data.data.attributes.entity.legalForm.id);
    }).then(response => {

        this.lei.legalEntity.legalForm = response.data.data.attributes.names[0].localName + ' ' + response.data.data.attributes.code;
        this.lei.legalEntity.jurisdiction = response.data.data.attributes.country + ' (' + response.data.data.attributes.countryCode + ')';

        this.resultReady = true;
    });
  }
}
