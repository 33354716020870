
import { Options, Vue } from "vue-class-component";
import RegisterStepperHeader from "@/components/register/stepper/register-stepper-header.vue";
import RegisterStepperUser from "@/components/register/stepper/register-stepper-user.vue";
import RegisterStepperCompany from "@/components/register/stepper/register-stepper-company.vue";
import RegisterStepperResult from "@/components/register/stepper/register-stepper-result.vue";
import RegisterStepperContact from "@/components/register/stepper/register-stepper-contact.vue";
import RegisterStepperSuccess from "@/components/register/stepper/register-stepper-success.vue";

@Options({
  components: {
    RegisterStepperResult,
    RegisterStepperContact,
    RegisterStepperCompany,
    RegisterStepperUser,
    RegisterStepperHeader,
    RegisterStepperSuccess,
  },
  emits: ["stepChange"],
})
export default class RegisterStepper extends Vue {
  currentRoute = "user";

  public setRoute(route: string) {
    this.currentRoute = route;
    this.$emit("stepChange", this.currentRoute);
  }
}
