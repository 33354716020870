
import {Options, Vue} from "vue-class-component";
import {Prop, Watch} from 'vue-property-decorator';

@Options({
  emits: ['change-route']
})
export default class RequestStepperHeader extends Vue {

  activeButton = 'company';
  finishedButton1 = {finished: false};
  finishedButton2 = {finished: false, disabled: false};
  finishedButton3 = {finished: false, disabled: false};
  finishedButton4 = {finished: false, disabled: false};

  @Prop() currentRoute!: string

  @Watch('currentRoute')
  changeRoute(newRoute: string) {
    this.activeButton = newRoute;
    if (newRoute === 'address') {
      this.finishedButton1.finished = true;
      this.finishedButton2.disabled = true;
    }
    if (newRoute === 'parent') {
      this.finishedButton2.finished = true;
      this.finishedButton3.disabled = true;
    }
    if (newRoute === 'result') {
      this.finishedButton3.finished = true;
      this.finishedButton4.disabled = true;
    }
    if (newRoute === 'start') {
      this.activeButton = 'company';
      this.finishedButton1 = {finished: false};
      this.finishedButton2 = {finished: false, disabled: false};
      this.finishedButton3 = {finished: false, disabled: false};
      this.finishedButton4 = {finished: false, disabled: false};
    }
  }

  activateButton(event: string) {
    this.activeButton = event;
    this.$emit('change-route', this.activeButton)
  }

  changeStep(step: string) {
    switch (step) {
      case 'address':
        if (this.finishedButton2.disabled) {
          this.activateButton(step);
        }
        break;
      case 'parent':
        if (this.finishedButton3.disabled) {
          this.activateButton(step);
        }
        break;
      case 'result':
        if (this.finishedButton4.disabled) {
          this.activateButton(step);
        }
    }
  }
}
