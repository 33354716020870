import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0d6bc5c2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "left" }
const _hoisted_2 = { class: "right" }
const _hoisted_3 = { id: "main" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("h2", _hoisted_1, _toDisplayString(_ctx.titleLeft), 1),
    _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.titleRight), 1),
    _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.titleMain), 1)
  ], 64))
}