
import { Options, Vue } from "vue-class-component";
import { searchModule } from "@/store/search/search";
import { Prop, Watch } from "vue-property-decorator";
import router from "@/router";
import BasePagination from "@/components/base/base-pagination.vue";

@Options({
  components: {
    BasePagination,
  },
})
export default class SearchResult extends Vue {
  @Prop() searchActive!: boolean;

  @Watch("currentPage")
  activePage() {
    this.getOption(this.option);
  }

  @Watch("searchActive")
  changeActive(active: boolean) {
    if (active) {
      this.displayResult();
    }
  }

  @Watch("option")
  filterStatus(option: string) {
    this.currentPage = 1;
    this.getOption(option);
  }

  getOption(option: string) {
    if (option === "ISSUED") {
      this.itemLength = Math.floor(this.issuedItems.length / 10);
      this.fillPages(this.issuedItems, this.issuedItems.length);
    } else if (option === "LAPSED") {
      this.itemLength = Math.floor(this.lapsedItems.length / 10);
      this.fillPages(this.lapsedItems, this.lapsedItems.length);
    } else if (option === "RETIRED") {
      this.itemLength = Math.floor(this.retiredItems.length / 10);
      this.fillPages(this.retiredItems, this.retiredItems.length);
    } else {
      this.itemLength = Math.floor(this.allItems.length / 10);
      this.fillPages(this.allItems, +this.amount);
    }
    if (this.itemLength < 1) {
      this.itemLength = 1;
    }
  }

  fillPages(
    items: {
      lei: string;
      name: string;
      js: { street: string; city: string; country: string };
      vs: { street: string; city: string; country: string };
      status: string;
    }[],
    amount: number
  ) {
    let counter = 1;
    this.activeItems = [];

    for (let i = this.currentPage * 10 - 10; i < amount; i++) {
      if (counter > 10) {
        break;
      }
      this.activeItems.push(items[i]);
      counter++;
    }
  }

  items: {
    lei: string;
    name: string;
    js: { street: string; city: string; country: string };
    vs: { street: string; city: string; country: string };
    status: string;
  }[] = [];
  amount = "";
  columns = ["", "LEI", "Name", "Juristischer Sitz", "Verwaltungssitz"];
  option = "Alle Status";
  allItems: {
    lei: string;
    name: string;
    js: { street: string; city: string; country: string };
    vs: { street: string; city: string; country: string };
    status: string;
  }[] = [];
  issuedItems: {
    lei: string;
    name: string;
    js: { street: string; city: string; country: string };
    vs: { street: string; city: string; country: string };
    status: string;
  }[] = [];
  lapsedItems: {
    lei: string;
    name: string;
    js: { street: string; city: string; country: string };
    vs: { street: string; city: string; country: string };
    status: string;
  }[] = [];
  retiredItems: {
    lei: string;
    name: string;
    js: { street: string; city: string; country: string };
    vs: { street: string; city: string; country: string };
    status: string;
  }[] = [];
  activeItems: {
    lei: string;
    name: string;
    js: { street: string; city: string; country: string };
    vs: { street: string; city: string; country: string };
    status: string;
  }[] = [];

  itemLength = 0;
  currentPage = 1;

  onPageChange(page: number) {
    this.currentPage = page;
  }

  displayResult() {
    this.items = searchModule.getItems;
    this.amount = searchModule.getAmount;
    this.option = "Alle Status";
    this.currentPage = 1;
    if (+this.amount > 100) {
      this.itemLength = Math.floor(+this.amount / 10);
    } else {
      this.itemLength = Math.floor(+this.amount / 10) + 1;
    }
    if (this.itemLength > 20) {
      this.itemLength = 20;
    }
    this.allItems = this.items;
    this.fillPages(this.allItems, +this.amount);
    this.issuedItems = this.allItems.filter((x) => x.status === "ISSUED");
    this.lapsedItems = this.allItems.filter((x) => x.status === "LAPSED");
    this.retiredItems = this.allItems.filter((x) => x.status === "RETIRED");
  }

  mounted() {
    if (+searchModule.getAmount > 1) {
      this.displayResult();
    }
  }

  showDetails(id: string) {
    router.push({ path: "/search/" + id });
  }
}
