import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6e3e3812"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "form"
}
const _hoisted_2 = {
  key: 1,
  class: "form"
}
const _hoisted_3 = {
  key: 2,
  class: "form"
}
const _hoisted_4 = {
  key: 3,
  class: "form"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_request_stepper_header = _resolveComponent("request-stepper-header")!
  const _component_request_stepper_company = _resolveComponent("request-stepper-company")!
  const _component_request_stepper_address = _resolveComponent("request-stepper-address")!
  const _component_request_stepper_parent = _resolveComponent("request-stepper-parent")!
  const _component_request_stepper_result = _resolveComponent("request-stepper-result")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_request_stepper_header, {
      onChangeRoute: _ctx.setRoute,
      currentRoute: _ctx.currentRoute
    }, null, 8, ["onChangeRoute", "currentRoute"]),
    (_ctx.currentRoute==='company' || _ctx.currentRoute==='start')
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_request_stepper_company, { onChangeRoute: _ctx.setRoute }, null, 8, ["onChangeRoute"])
        ]))
      : (_ctx.currentRoute==='address')
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_request_stepper_address, { onChangeRoute: _ctx.setRoute }, null, 8, ["onChangeRoute"])
          ]))
        : (_ctx.currentRoute==='parent')
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_request_stepper_parent, { onChangeRoute: _ctx.setRoute }, null, 8, ["onChangeRoute"])
            ]))
          : (_ctx.currentRoute==='result')
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(_component_request_stepper_result, { onChangeRoute: _ctx.setRoute }, null, 8, ["onChangeRoute"])
              ]))
            : _createCommentVNode("", true)
  ], 64))
}