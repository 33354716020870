
import {Vue} from 'vue-class-component';
import {Prop} from 'vue-property-decorator';

export default class SelectorSalutation extends Vue {

  placeholder = 'Bitte wählen';
  salutations = [
    'Frau',
    'Herr',
    'Madame',
    'Monsieur',
    'Mr.',
    'Mrs.',
    'Señor',
    'Sig.',
    'Signor',
    'Signora',
    'Diverso'
  ];

  @Prop() modelValue!: string
  @Prop() onBlur!: string
  @Prop() valid!: boolean

  onChange(event: string) {
    this.$emit("update:modelValue", event);
  }
}
