const stringUtil = {
    formatDateString: function(dateString: string){ //Hotfix, da die datenfelder als string deklariert wurden
        const parts = dateString.split(/[.,/ -]/);
        const date = new Date(parseInt(parts[2], 10),
            parseInt(parts[1], 10) - 1,
            parseInt(parts[0], 10));
        return this.formatDate(date);
    },
    formatDate(date: Date){
        return date.toLocaleDateString('de-DE', {year: 'numeric', day: '2-digit', month: '2-digit' });
    },
    capitalizeFirstLetter(string: string) {
        string = string.toLowerCase();
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
}
export default stringUtil;