import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f4a4d3ca"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "label" }
const _hoisted_2 = { class: "auto" }
const _hoisted_3 = { class: "label" }
const _hoisted_4 = { class: "auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_label = _resolveComponent("base-label")!
  const _component_base_autocomplete = _resolveComponent("base-autocomplete")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_base_label, { title: 'PLZ*' }),
      _createVNode(_component_base_label, {
        margin: '345px',
        title: 'Stadt*'
      })
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_base_autocomplete, {
        items: _ctx.autocompletePlzData,
        modelValue: _ctx.plz.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.plz.value) = $event)),
        valid: _ctx.plz.isValid,
        onBlur: _ctx.validatePlz,
        onKeyUp: _ctx.autocompletePlz,
        auto: 'plz',
        dropdown: true,
        onUpdateModel: _ctx.updateModel,
        width: '300px'
      }, null, 8, ["items", "modelValue", "valid", "onBlur", "onKeyUp", "onUpdateModel"]),
      _createVNode(_component_base_autocomplete, {
        items: _ctx.autocompleteCityData,
        modelValue: _ctx.city.value,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.city.value) = $event)),
        valid: _ctx.city.isValid,
        onBlur: _ctx.validateCity,
        onKeyUp: _ctx.autocompleteCity,
        auto: 'city',
        dropdown: true,
        onUpdateModel: _ctx.updateModel,
        width: '300px'
      }, null, 8, ["items", "modelValue", "valid", "onBlur", "onKeyUp", "onUpdateModel"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_base_label, { title: 'Straße*' }),
      _createVNode(_component_base_label, {
        margin: '330px',
        title: 'Nr*'
      })
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_base_autocomplete, {
        items: _ctx.autocompleteStreetData,
        modelValue: _ctx.street.value,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.street.value) = $event)),
        valid: _ctx.street.isValid,
        dropdown: true,
        onBlur: _ctx.validateStreet,
        onKeyUp: _ctx.autocompleteStreet,
        width: '300px'
      }, null, 8, ["items", "modelValue", "valid", "onBlur", "onKeyUp"]),
      _createVNode(_component_base_autocomplete, {
        items: _ctx.autocompleteNrData,
        modelValue: _ctx.nr.value,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.nr.value) = $event)),
        valid: _ctx.nr.isValid,
        dropdown: false,
        onBlur: _ctx.validateNr,
        onKeyUp: _ctx.autocompleteNr,
        width: '40px'
      }, null, 8, ["items", "modelValue", "valid", "onBlur", "onKeyUp"])
    ])
  ], 64))
}