
import { Vue } from "vue-class-component";
import { leiStorageModule } from "@/store/lei-storage";
import { cartModule } from "@/store/cart/cart";
import router from "@/router";
import stringUtil from "@/util/stringUtil";
import { Lei } from "@/store/model";

export default class DashboardTable extends Vue {
  StringUtil = stringUtil;

  leis = leiStorageModule.getLeis;
  cart = cartModule.getCartItems;
  amount = 0;
  isInShoppingCart: boolean[] = [];

  addItem(lei: Lei) {
    cartModule.actionAddCartItem(lei);
    cartModule.actionAddType("extension");
    this.amount++;
  }

  showDetails(lei: string) {
    router.push({ path: "/dashboard/" + lei });
  }

  showCart() {
    router.push({ path: "/cart" });
  }

  mounted() {
    this.amount = cartModule.getCartAmount;
    for (let i = 0; i < this.leis.length; i++) {
      let result = this.cart.find((cartItem) => cartItem === this.leis[i]);
      if (result) {
        this.isInShoppingCart.push(true);
      } else {
        this.isInShoppingCart.push(false);
      }
    }
  }
}
