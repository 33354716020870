
import { Prop } from "vue-property-decorator";
import { Vue } from "vue-class-component";

export default class BaseLabel extends Vue {
  @Prop() title!: string;
  @Prop() margin!: string;

  get text() {
    return this.title;
    // return this.title.toUpperCase();
  }
}
