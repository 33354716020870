
import {Options, Vue} from "vue-class-component";
import BaseToggle from '@/components/base/base-toggle.vue';
import RequestBaseAddress from '@/components/request/base/request-base-address.vue';
import BaseButton from '@/components/base/base-button.vue';
import {addressModule} from '@/store/request/address';


@Options({
  components: {
    RequestBaseAddress,
    BaseToggle,
    BaseButton,
  },
  emits: ['change-route'],
})
export default class RequestStepperAddress extends Vue {

  value = true;

  plz = '';
  city = '';
  street = '';
  nr = '';

  juPlz = '';
  juCity = '';
  juStreet = '';
  juNr = '';

  apiKey = 'cn9bewzqlYNTFyQhy7Hky3O64MzA5xinLuACfHzccEU';
  formJuValid = true;
  formValid = true;
  addressValid = true;
  addressJuValid = true;

  // eslint-disable-next-line
  getAddressInterface(companyInterface: any) {
    this.$options.addressInterface = companyInterface;
  }

  // eslint-disable-next-line
  getAddressJuInterface(companyInterface: any) {
    this.$options.addressJuInterface = companyInterface;
  }

  validAddress(valid: boolean, plz: string, city: string, street: string, nr: string) {
    this.formValid = valid;
    this.plz = plz;
    this.city = city;
    this.street = street;
    this.nr = nr;
  }

  validJuAddress(valid: boolean, plz: string, city: string, street: string, nr: string) {
    this.formJuValid = valid;
    this.juPlz = plz;
    this.juCity = city;
    this.juStreet = street;
    this.juNr = nr;
  }

  backToCompany() {
    this.$emit('change-route', 'company')
  }

  submitAddress() {
    if (!this.value) {
      this.$options.addressJuInterface.isFormValid();
    }
    this.$options.addressInterface.isFormValid();
    if (this.formValid) {

      const param = [];
      param.push('Deutschland')
      param.push(this.plz);
      param.push(this.city);
      param.push(this.street);
      param.push(this.nr);

      this.axios.get('https://geocode.search.hereapi.com/v1/autocomplete', {
        params: {
          q: param.toString(),
          apiKey: this.apiKey
        }
      }).then((response) => {
        if (response.data.items.length > 0) {
          const result = response.data.items[0].title.split(' ');
          this.addressValid = result.length > 4;
          if (this.addressValid) {
            addressModule.actionSetPlz(this.plz);
            addressModule.actionSetCity(this.city);
            addressModule.actionSetStreet(this.street);
            addressModule.actionSetNr(this.nr);
            addressModule.setValue(this.value)
            if (!this.value) {
              if (this.formJuValid) {
                this.checkJuAddress();
              }
            } else {
              addressModule.setAddressForm(true);
              this.$emit('change-route', 'parent');
            }
          }
        } else {
          this.addressValid = false;
        }
      })
    }
  }

  checkJuAddress() {
    const param = [];
    param.push('Deutschland')
    param.push(this.juPlz);
    param.push(this.juCity);
    param.push(this.juStreet);
    param.push(this.juNr);

    this.axios.get('https://geocode.search.hereapi.com/v1/autocomplete', {
      params: {
        q: param.toString(),
        apiKey: this.apiKey
      }
    }).then((response) => {
      if (response.data.items.length > 0) {
        const result = response.data.items[0].title.split(' ');
        this.addressJuValid = result.length > 4;
        if (this.addressJuValid) {
          addressModule.actionSetJuPlz(this.juPlz);
          addressModule.actionSetJuCity(this.juCity);
          addressModule.actionSetJuStreet(this.juStreet);
          addressModule.actionSetJuNr(this.juNr);
          addressModule.setAddressForm(true);
          this.$emit('change-route', 'parent');
        }
      } else {
        this.addressJuValid = false;
      }
    })
  }


  mounted() {
    this.value = addressModule.getValue;
  }
}
