
import { Options, Vue } from "vue-class-component";
import BaseButton from "@/components/base/base-button.vue";
import { userStorageModule } from "@/store/user-storage";

@Options({
  components: {
    BaseButton,
  },
  emits: ["change-route"],
})
export default class RegisterStepperSuccess extends Vue {
  showUser() {
    this.$router.replace("/user/" + (userStorageModule.getUsers.length - 1));
  }
}
